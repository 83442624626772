import React ,{useState} from 'react'
import Modal from 'react-modal'
import LayoutTransporter from '../../Components/LayoutTransporter/LayoutTransporter'
import {
	FormSectionCard,
	GradientText,
	SubmitButton,
	SubmitButtonContainer,
} from '../PasserCommande/PasserCommande.styles'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { CustomInput } from '../RegisterTransporter/style'
import { CreateUser } from '../../api/auth'
import { useSelector } from 'react-redux'

import 'flatpickr/dist/themes/material_blue.css'
import Flatpickr from 'react-flatpickr'
import { French } from 'flatpickr/dist/l10n/fr.js'
import { Controller } from 'react-hook-form'
import moment from 'moment'
import toast from 'react-hot-toast'

Modal.setAppElement('#root')

function CreateChauffeur(props) {
	const { register, handleSubmit, control,formState: { errors } } = useForm()

	const history = useHistory()
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [loading,setLoading]=useState(false)
	const currentUser = useSelector((state) => state.auth.user)

	const onSubmit = async (data) => {
		console.log('data',data)
		if (data.password !== data._password) {
			toast.error('Veuillez vérifier votre mot de passe', {
			  style: {
				fontFamily: 'Montserrat',
			  },
			});
			return;
		  }
		
		delete data._password

		data.date_embauche= moment(data.date_embauche).toISOString()
		data.date_naiss= moment(data.date_naiss).toISOString()
		setLoading(true)
		try {
			await CreateUser({
				...data,
				role: 'chauffeur',
				transporter: currentUser._id,
			})

			setModalIsOpen(true)
		} catch (error) {
			const data = error?.response?.data?.errors
			if (data?.msg === 'EMAIL_ALREADY_EXISTS') {
				toast.error(
					'Ce mail est déjà utilisé par un autre utilisateur.',
					{
						style: {
							fontFamily: 'Montserrat',
						},
					}
				)
			}else{
				toast.error("Une erreur s'est produite", {
					style: {
						fontFamily: 'Montserrat',
					},
				});
			}
		}finally{
			setLoading(false)
		}
	}

	return (
		<LayoutTransporter>
			<div
				style={{
					//height: '80vh',
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
				}}
			>
				<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<div
							onClick={() => {
								history.goBack()
							}}
							style={{
								background: '#51C7F2',
								width: '3rem',
								height: '3rem',
								borderRadius: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								cursor: 'pointer',
							}}
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width={14.017}
								height={14.11}
								viewBox='0 0 14.017 14.11'
							>
								<g
									data-name='Group 11287'
									fill='none'
									stroke='#fff'
									strokeWidth={2}
								>
									<path
										data-name='Path 11687'
										d='M16.6 11.818l-6.348 6.349 2.115 2.115 4.232 4.232'
										transform='translate(-8.838 -11.113)'
									/>
									<path
										data-name='Path 11688'
										d='M22.147 11.818l-6.348 6.349 6.348 6.348'
										transform='translate(-8.838 -11.113)'
									/>
								</g>
							</svg>
						</div>

						<div
							style={{
								width: '1rem',
							}}
						/>

						<GradientText marginTop={'unset'}>
							{'AJOUTER CHAUFFEUR'.toUpperCase()}
						</GradientText>
					</div>
				<div
					style={{
						width: '70%',
						fontFamily: 'Montserrat',
					}}
				>
					<form onSubmit={handleSubmit(onSubmit)}>
						<FormSectionCard>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										marginBottom: '1rem',
									}}
								>
									Sexe<span style={{ color: 'red' }}>*</span>
								</span>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
									}}
								>
									<div
										style={{
											width: '30%',
										}}
									>
										<input
											type='radio'
											style={{
												marginRight: '1rem',
												transform: 'scale(1.5)',
											}}
											{...register('sexe', {
												required: true,
											})}
											value={'homme'}
										/>

										<span>Homme</span>
									</div>

									<div>
										<input
											type='radio'
											style={{
												marginRight: '1rem',
												transform: 'scale(1.5)',
											}}
											{...register('sexe', {
												required: true,
											})}
											value={'femme'}
										/>

										<span>Femme</span>
									</div>
										
									
								</div>
								{errors.sexe && (
                                     <div style={{ color: 'red', marginTop: '0.5rem',fontSize:"14px" }}>
                                     Veuillez sélectionner le sexe
                                     </div>
                                )}
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										marginBottom: '1rem',
									}}
								>
									Permis<span style={{ color: 'red' }}>*</span>
								</span>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
									}}
								>
									{['Léger', 'Lourds', 'Super lourds'].map(
										(item, index) => (
											<div
												key={String(index)}
												style={{
													width: '20%',
												}}
											>
												<input
													type='checkbox'
													style={{
														marginRight: '1rem',
														transform: 'scale(1.5)',
													}}
													{...register('permis', {
														required: true,
													})}
													value={item}
												/>

												<span>{item}</span>
											</div>
										)
									)}
								</div>
								{errors.permis && (
                                     <div style={{ color: 'red', marginTop: '0.5rem',fontSize:"14px" }}>
                                     Veuillez sélectionner le type de permis
                                     </div>
                                )}
							</div>

							
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: '2rem',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Nom <span style={{ color: 'red' }}>*</span>
									</span>
									<CustomInput
										type='text'
										placeholder=''
										{...register('last_name', {
											required: 'Veuillez indiquer le nom',
											minLength: {
												value: 3,
												message: 'Le nom  doit contenir au moins 3 caractères.',
											  },
											  pattern: {
												value: /^[A-Za-zÀ-ÖØ-öø-ÿ\s'\-]+$/,
												message: 'Le nom  doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
											  },
										})}
									/>
									{errors.last_name && (
                                    <div style={{ color: 'red', marginTop: '0.5rem' ,fontSize:'14px'}}>
                                     {errors.last_name.message}
                                    </div>
                                    )}
								</div>

								<div style={{ width: 50 }} />

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Prénom <span style={{ color: 'red' }}>*</span>
									</span>
									<CustomInput
										type='text'
										placeholder=''
										{...register('first_name', {
											required: 'Veuillez indiquer le prénom.',
											minLength: {
												value: 3,
												message: 'Le prénom doit contenir au moins 3 caractères.',
											  },
											  pattern: {
												value: /^[A-Za-zÀ-ÖØ-öø-ÿ\s'\-]+$/,
												message: 'Le prénom doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
											  },
										})}
									/>
									  {errors.first_name && (
                                      <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                       {errors.first_name.message}
                                       </div>
                                       )}
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: '2rem',
								}}
							>
								<div
									style={{
										width: '100%',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Adresse <span style={{ color: 'red' }}>*</span>
									</span>
									<CustomInput
										type='text'
										placeholder=''
										{...register('address', {
											required: ' Veuillez indiquer l\'adresse .',
											minLength: {
												value: 3,
												message: 'L\'adresse doit contenir au moins 3 caractères.',
											},
											pattern: {
												value: /^[A-Za-z0-9\s',-]+$/,
												message: 'L\'adresse doit contenir des lettres, des chiffres, des espaces, des apostrophes et des tirets uniquement.',
											},
										})}
									/>
									{errors.address && (
                                     <div style={{ color: 'red', marginTop: '0.5rem',fontSize:"14px" }}>
                                     {errors.address.message}
                                     </div>
                                    )}
								</div>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Ville <span style={{ color: 'red' }}>*</span>
								</span>
								<CustomInput
									type='text'
									{...register('city', {
									 	required: 'Veuillez indiquer la ville.',
										 minLength: {
											value: 3,
											message: 'La ville doit contenir au moins 3 caractères.',
										  },
										  pattern: {
											value: /^.+$/,
											message: 'La ville doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
										  },
									})}
								/>
								{errors.city && (
                                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                {errors.city.message}
                                </div>
                               )}
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Code Postal <span style={{ color: 'red' }}>*</span>
								</span>
								<CustomInput
									type='text'
									{...register('postalCode', {
										required: 'Veuillez indiquer le code postal.',
										pattern: {
											value: /^\d{5}$/,
											message: 'Le code postal doit contenir exactement 5 chiffres.',
										  },
									})}
								/>
								{errors.postalCode && (
                                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                {errors.postalCode.message}
                                </div>
                               )}
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: '2rem',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Téléphone <span style={{ color: 'red' }}>*</span>
									</span>
									<CustomInput
										type='text'
										placeholder=''
										{...register('phone', {
											required: 'Veuillez indiquer le numéro de téléphone .',
											pattern: {
												value: /^[0-9]+$/, 
												message: 'Veuillez entrer un numéro de téléphone valide contenant uniquement des chiffres',
													},

										})}
									/>
									{errors.phone && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.phone.message}
                                    </div>
                                    )}
								</div>

								<div style={{ width: 50 }} />

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Adresse email <span style={{ color: 'red' }}>*</span>
									</span>
									<CustomInput
										type='text'
										placeholder=''
										{...register('email', {
											required: "Veuillez indiquer l'adresse e-mail.",
											pattern: {
												value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
												message: 'Veuillez entrer une adresse e-mail valide',
											  },
										})}
									/>
										{errors.email && (
                                         <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                         {errors.email.message}
                                         </div>
                                        )}
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: '2rem',
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										width: '50%',
									}}
								>
									<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										Date de naissance <span style={{ color: 'red' }}>*</span>
									</span>
									<Controller
										name='date_naiss'
										rules={{ required: "Veuillez indiquer la date de naissance.",
										    validate: value => {
											const today = new Date();
											const birthDate = new Date(value);
											const age = today.getFullYear() - birthDate.getFullYear();
											birthDate.setFullYear(today.getFullYear());
							 
											if (
											   age < 18 ||
											   (age === 18 &&
												  today.getTime() < birthDate.getTime())
											) {
											   return 'Vous devez avoir au moins 18   ans pour travailler.';
											}
							 
											return true;
										 },
									
									    }}
										control={control}
										render={({
											field: { onChange, value },
										}) => (
											<Flatpickr
												onChange={([date]) => {
													onChange(date)
												}}
												className='date-input'
												options={{
													mode: 'single',
													locale: French,
													dateFormat: 'd/m/Y',
												}}
												value={value}
											/>
										)}
									/>
									{errors.date_naiss && (
                                         <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                         {errors.date_naiss.message}
                                         </div>
                                )}
								</div>

								<div style={{ width: 50 }} />

								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										width: '50%',
									}}
								>
									<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										Date d’embauche <span style={{ color: 'red' }}>*</span>
									</span>
									
									<Controller
										name='date_embauche'
										rules={{ required: "Veuillez indiquer la date d’embauche.",
										validate: (value) => {
											const today = moment().startOf('day');
											const selectedDate = moment(value, 'DD/MM/YYYY').startOf('day');
								  
											if (selectedDate.isAfter(today)) {
											  return "La date d'embauche doit être inférieure ou égale à la date actuelle.";
											}
								  
											return true;
										  }
								  
										
								   
									    }}
										control={control}
										render={({
											field: { onChange, value },
										}) => (
											<Flatpickr
												onChange={([date]) => {
													onChange(date)
												}}
												className='date-input'
												options={{
													mode: 'single',
													locale: French,
													dateFormat: 'd/m/Y',
												}}
												value={value}
											/>
										)}
									/>
									{errors.date_embauche && (
                                         <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                         {errors.date_embauche.message}
                                         </div>
                                )}
								</div>
							</div>
								
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										marginBottom: '1rem',
									}}
								>
									Fimo <span style={{ color: 'red' }}>*</span>
								</span>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
									}}
								>
									{['Oui'].map((item, index) => (
										<div
											key={String(index)}
											style={{
												width: '20%',
											}}
										>
											<input
												type='radio'
												style={{
													marginRight: '1rem',
													transform: 'scale(1.5)',
												}}
												{...register('fimo',{required:true})}
												value={item}
											/>

											<span>{item}</span>
										</div>
									))}
									{['Non'].map((item, index) => (
										<div
											key={String(index)}
											style={{
												width: '20%',
											}}
										>
											<input
												type='radio'
												style={{
													marginRight: '1rem',
													transform: 'scale(1.5)',
												}}
												{...register('fimo',{required:true})}
												value={item}
											/>

											<span>{item}</span>
										</div>
									))}
								</div>
								{errors.fimo && (
                            <div style={{ color: 'red',marginTop:'0.5rem'}}>
                              Veuillez sélectionner une option pour Fimo.
                             </div>
                             )}
							</div>
							
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										marginBottom: '1rem',
									}}
								>
									Matière dangereuse <span style={{ color: 'red' }}>*</span>
								</span>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
									}}
								>
									{['Oui'].map((item, index) => (
										<div
											key={String(index)}
											style={{
												width: '20%',
											}}
										>
											<input
												type='radio'
												style={{
													marginRight: '1rem',
													transform: 'scale(1.5)',
												}}
												{...register('danger',{required:true})}
												value={item}
											/>

											<span>{item}</span>
										</div>
									))}
										{['Non'].map((item, index) => (
										<div
											key={String(index)}
											style={{
												width: '20%',
											}}
										>
											<input
												type='radio'
												style={{
													marginRight: '1rem',
													transform: 'scale(1.5)',
												}}
												{...register('danger',{required:true})}
												value={item}
											/>

											<span>{item}</span>
										</div>
									))}
								</div>
								{errors.danger && (
                            <div style={{ color: 'red',marginTop:'0.5rem'}}>
                              Veuillez sélectionner une option pour Matière dangereuse.
                             </div>
                             )}
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Mot de passe <span style={{ color: 'red' }}>*</span>
									</span>
									<CustomInput
										type='password'
										placeholder=''
										{...register('password', {
											required: 'Veuillez indiquer le mot de passe',
											minLength: {
												value: 8,
												message: 'Le mot de passe doit contenir au moins 8 caractères',
													},
										})}
									/>
									{errors.password && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.password.message}
                                    </div>
                                    )}
								</div>

								<div style={{ width: 50 }} />

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Confirmer mot de passe <span style={{ color: 'red' }}>*</span>
									</span>
									<CustomInput
										type='password'
										placeholder=''
										{...register('_password', {
											required: 'Veuillez indiquer le mot de passe',
											minLength: {
												value: 8,
												message: 'Le mot de passe doit contenir au moins 8 caractères',
													},
										})}
									/>
										{errors._password && (
                                        <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                        {errors._password.message}
                                         </div>
                                        )}
								</div>
							</div>
						</FormSectionCard>

						<div style={{ height: '1rem' }} />

						<SubmitButtonContainer>
							<SubmitButton type='submit' disabled={loading}>
								
								{loading ? (
									<div className='loader'></div>
								) : (
									'Enregistrer'
								)}
							</SubmitButton>
						</SubmitButtonContainer>
					</form>
				</div>
			</div>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => {}}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '35rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<GradientText>{'Félicitation'.toUpperCase()}</GradientText>

					<div
						style={{
							marginTop: 20,
						}}
					>
                    Votre chauffeur a été ajouté avec succès! 😃	
					<br />
					Un e-mail de confirmation a été envoyé à son adresse e-mail.
					 <br />
					 Veuillez informer votre chauffeur de vérifier sa boîte de réception et de cliquer sur le lien fourni pour finaliser la création de son compte.
					</div>

					<button
						onClick={() => {
							history.push(`/chauffeurs`)
						}}
						style={{
							padding: '0.5rem 1rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 30,
						}}
					>
						OK
					</button>
				</div>
			</Modal>
		</LayoutTransporter>
		
	)
}

export default CreateChauffeur
