import React, { useEffect, useState } from 'react'
import { getNotValidClients,getValidClients, getClients ,affecterClientACommercial,removeClientFromCommercial} from '../../api/clients'
import { useSelector } from 'react-redux';
import {getValidatedCommercial} from '../../api/commerciaux'
import DataTable from 'react-data-table-component'
import { GradientText } from '../PasserCommande/PasserCommande.styles'
import LayoutAdmin from '../../Components/LayoutAdmin/LayoutAdmin'
import Modal from 'react-modal'
import { fullTextSearchClientsForAdmin } from '../../api/search'
import moment from 'moment'
import { useHistory } from 'react-router'
import Select from 'react-select'
import toast from 'react-hot-toast'

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')

function MesClients() {
	const history = useHistory()
	const token = useSelector((state) => state.auth.token);
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [modalIsOpenRemove, setModalIsOpenRemove] = useState(false)
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)
	const [chargementEnCours,setChargementEnCours]=useState(false)
	const [commercial,setCommercial]=useState([])
	const [selectedCommercial, setSelectedCommercial] = useState(null)
	const [totalRows, setTotalRows] = useState(0)
	const [perPage, setPerPage] = useState(10)
	const [currentPage, setCurrentPage] = useState(1)
	const [filterStatus, setFilterStatus] = useState('all');
	const [searchQuery, setSearchQuery] = useState('')
	const [clearSelected, setClearSelected] = useState(false);
    const [selected, setSelected] = useState([])
	const [isSearching,setIsSearching]=useState(false)
	
	const fetchClients = async (page,perPage) => {
		setChargementEnCours(true);
		try {
		  let res;
		  if (isSearching) {
			if (searchQuery.length === 0) {
			  res = await getClients(page, perPage, token);
			} else {
			  res = await fullTextSearchClientsForAdmin(searchQuery, filterStatus,page,perPage, token);
			}
		  } else {
			if (filterStatus === 'all') {
			  res = await getClients(page, perPage, token);
			} else if (filterStatus === 'validated') {
			  res = await getValidClients(page, perPage, token);
			} else if (filterStatus === 'notValidated') {
			  res = await getNotValidClients(page, perPage, token);
			}
		  }
		  setData(res.docs);
		  setTotalRows(res.totalDocs);
		} catch (error) {
		  toast.error("Une erreur s'est produite", {
			style: {
			  fontFamily: 'Montserrat',
			},
		  });
		} finally {
		  setChargementEnCours(false);
		}
	  };
	const fetchCommercial = async () => {
		try {
			const res=await getValidatedCommercial(token)
			setCommercial(res)
		} catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			})
		}
	}

	const handlePageChange = (page) => {
		setCurrentPage(page)
		fetchClients(page,perPage)
	}

	const handlePerRowsChange = async (newPerPage, page) => {
		console.log('newPerPage',newPerPage)
		fetchClients(page,newPerPage)
		setPerPage(newPerPage)

	}

	const handleSelectedRowsChange = (state) => {
		setSelected(state.selectedRows);
		setClearSelected(false); 
	};
	const  handleSearch=async()=>{
		setIsSearching(true)
		setCurrentPage(1)
		if(isSearching){
			await fetchClients(1,perPage)
		}
	}
	const handelReset=async()=>{
		setIsSearching(false)
		setSearchQuery('')
	}
	useEffect(() => {
		fetchClients(currentPage,perPage)
		fetchCommercial()

	}, [])
	
	
	useEffect(() => {
		fetchClients(currentPage,perPage)
	}, [filterStatus,isSearching])


	const columns = [
		{
			name: 'ID',
			selector: (row) => row.__id,
			sortable: false,
			width:'150px'
		},
		{
			name: 'Nom société',
			selector: (row) => `${row.societe}`,
			sortable: false,
			width:'150px'
		},
		{
			name: 'Nom du client',
			selector: (row) => `${row.first_name} ${row.last_name}`,
			sortable: false,
			width:'200px'
		},
		{
			name: 'Email',
			selector: (row) => `${row.email}`,
			sortable: false,
			width:'250px'
		},
		{
			name: 'Type de compte',
			selector: (row) => (row.premium ? 'Premium' : 'Ordinaire'),
			sortable: false,
			width:'200px'
		},
		{
			name: "Date d'inscription",
			selector: (row) => moment(row.createdAt).format('DD/MM/YYYY'),
			sortable: false,
			width:'200px'
		},
		{
			name: '% de réduction',
			selector: (row) => (row.reduction ? `${row.reduction}%` : '0%'),
			sortable: false,
			width:'200px',
		},
		{
			name: "Date de suspension",
			selector: (row) => (row?.suspendre ? moment(row?.dateSuspension).format('DD/MM/YYYY') : '__'),
			sortable: false,
			width: "200px"
		},
		{
			name: 'Status',
			selector: (row) => (row.suspendre ? 'Suspendu' : 'Activé'),
			sortable: false,
			width:'200px',
		},
		{
			name: 'Commercial',
			sortable: false,
            width:'200px',
            cell: (row) => (
                <div
						
						style={{
							cursor: 'pointer',
                            color:'#1C63C5'
						}}
				>
                   {row?.commercial ? 
				   <div
				   onClick={() => {
					history.push(`/admin/commerçants/${row?.commercial?._id}`)
				}}>
                  {row.commercial.__id}
					</div>
				   : "__"}
                    </div>
            )
		},
		{
			name: 'Actions',
			button: true,
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						onClick={() => {
							history.push(`/admin/clients/${row._id}`)
						}}
						style={{
							cursor: 'pointer',
						}}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='21.75'
							height='27.466'
							viewBox='0 0 21.75 27.466'
						>
							<g
								id='Group_11097'
								data-name='Group 11097'
								transform='translate(-10999.709 -6280)'
							>
								<path
									id='Path_11755'
									data-name='Path 11755'
									d='M11013.243,6280l8.216,8.216-12.913,12.914h-8.837v-8.244Z'
									fill='#b4b4c3'
								/>
								<path
									id='Path_11756'
									data-name='Path 11756'
									d='M10999.709,6306.466h20.784'
									fill='none'
									stroke='#e7e7eb'
									strokeWidth='2'
								/>
							</g>
						</svg>
					</div>
				</div>
			),
		},
	]

	return (
		<LayoutAdmin>
			<div
				style={{
					//height: '80vh',
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
				<GradientText marginTop={'0'}>
					{'Mes clients'.toUpperCase()}
				</GradientText>

				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '2rem',
						marginTop: '1rem',
					}}
				>
					<input
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
						placeholder='Rechercher par identifiant,nom client / societe'
						style={{
							padding: '1rem',
							borderRadius: 5,
							border: '1px solid #E4EAF0',
							width: '22rem',
							fontFamily: 'Montserrat',
						}}
					/>
                    <select

                    value={filterStatus}
                    onChange={(e) => {
                    setFilterStatus(e.target.value);
                   
                    }}
                    style={{
						padding: '1rem',
						borderRadius: 5,
						border: '1px solid #E4EAF0',
						width: '15rem',
						fontFamily: 'Montserrat',
						marginLeft:'1rem'
					}}
                    >
                     <option value="all">Tous</option>
                     <option value="validated">Vérifiés</option>
                     <option value="notValidated">Non vérifiés</option>
                    </select>
					<button
						disabled={chargementEnCours}
						onClick={handleSearch}
						style={{
							padding: '1rem 2rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 20,
							marginLeft: 20,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Rechercher
					</button>

					<button
						onClick={handelReset}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Effacer
					</button>
					<button
						onClick={() => {
							if (selected.length === 0) {
								toast.error(
									'Il faut séléctionner des clients',
									{
										style: {
											fontFamily: 'Montserrat',
										},
										id: 'MesTransports',
									}
								)
								return								
							}

							setModalIsOpen(true)
						}}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9F7EC',
							border: 'none',
							cursor: 'pointer',
							color: '#1CC523',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '0.5rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Attribuer
					</button>
					<button
						onClick={async () => {
							if (selected.length === 0) {
								toast.error(
									'Il faut séléctionner des clients',
									{
										style: {
											fontFamily: 'Montserrat',
										},
										id: 'MesTransports',
									}
								)

								return
							}
							setModalIsOpenRemove(true)
                            
							}}
						style={{
							background: '#C9D5F7',
							color: '#1C63C5',
							padding: '0.6rem 1rem',
							height: '3rem',
							border: 'none',
							cursor: 'pointer',
							borderRadius: 7,
							marginLeft: '0.5rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Désattribuer
					</button>
				</div>
				<div
					style={{
						width: '100%',
						padding: '0 1rem',
					}}
				>
					<DataTable
						columns={columns}
						data={data}
						progressPending={chargementEnCours}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						selectableRows
						clearSelectedRows={clearSelected}
						onSelectedRowsChange={handleSelectedRowsChange}
						noDataComponent="Il n'y a pas des clients à afficher"
						progressComponent={
							<div
							style={{
							  display: 'flex',
							  alignItems: 'center',
							  justifyContent: 'center',
							  backgroundColor: 'white',
							  marginTop:"10rem"
							}}
							>
							<div className='customLoader'></div>	
							</div>
						}
						paginationComponentOptions={{
							rowsPerPageText: 'Rangs par page',
							rangeSeparatorText: 'sur',
						}}
					/>
				</div>
			</div>
			<Modal
				isOpen={modalIsOpen}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}} >Affecter un Client à un Commercial</div>

					<div
						style={{
							marginTop: 20,
							width: '100%',
						}}
					>
						<Select
							options={commercial?.map((t) => ({
								value: t._id,
								label: `${t.first_name} ${t.last_name}`,
							}))}
							styles={{
								option: (provided, state) => ({
									...provided,
									fontFamily: 'Montserrat',
								}),
							}}
							onChange={(x) => setSelectedCommercial(x.value)}
							menuPortalTarget={document.querySelector('body')}
						/>
					</div>

					<div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '2rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
						disabled={loading}
						onClick={async () => {
							setLoading(true)
							try {
								const response=await affecterClientACommercial(
									selected.map((c) =>  ({
										_id: c._id,
										__id: c.__id
									})),
									selectedCommercial,
									token
								)
							
								if(response.errors){
									toast.error(response.errors,{
										style: {
											 fontFamily: 'Montserrat',
									}})
								}
								if(response.success){
									toast.success(response.success,{
										style: {
											 fontFamily: 'Montserrat',
									}})
								}

								
								
							} catch (error) {
								toast.error(error.response?.data.error || "Erreur lors de l'attribution des clients", {
									style: {
										fontFamily: 'Montserrat',
									},
								})

							}finally{
							setClearSelected(true)
							setSelected([])
							setSelectedCommercial(null)
							setLoading(false)
				            setModalIsOpen(false)
                            await fetchClients(currentPage,perPage)
							}

							
							
						}}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
							display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
						}}
					>
						{loading ? (
									<div className='loader'></div>
								) : (
									'Valider'
						)}
					</button>
					<button
						onClick={ () => {
							setClearSelected(true)
							setSelected([])
							setSelectedCommercial(null)
							setModalIsOpen(false)								
						}}
						style={{
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
				
				</div>
			</Modal>
		
			<Modal
				isOpen={modalIsOpenRemove}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}} 
					>Retirer le Commercial</div>

					<div
						style={{
							marginTop: 20,
							width: '100%',
						}}
					>
						Êtes-vous sûr de vouloir retirer les commerciaux assignés à ces clients sélectionnés ?
					</div>

					
					<div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
				    <button
						disabled={loading}
						onClick={async () => {
							setLoading(true)
							try {
								
								const response=await removeClientFromCommercial(
									selected.map((c) =>  ({
										_id: c._id,
										__id: c.__id
									})),
									token
								)
								if(response.errors){
									toast.error(response.errors,{
										style: {
											 fontFamily: 'Montserrat',
									}})
								}
								if(response.success){
									toast.success(response.success,{
										style: {
											 fontFamily: 'Montserrat',
									}})
								}								
							} catch (error) {
								toast.error(error.response?.data.error || "Erreur lors de la désattribution des clients", {
									style: {
										fontFamily: 'Montserrat',
									},
								})
							}
							finally {
								setClearSelected(true)
								setLoading(false)
								setSelected([])
								setModalIsOpenRemove(false)
								await fetchClients(currentPage,perPage)
							}

							
							
						}}
						
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
							display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
						}}
					>
							{loading ? (
									<div className='loader'></div>
								) : (
									'Valider'
						)}
					</button>
					<button
						onClick={ () => {
							setClearSelected(true)
							setSelected([])
							setModalIsOpenRemove(false)
												
						}}
						style={{
							// padding: '1rem 1.5rem',
							width:"150px",
							height:"50px",
							background: 'red',
							marginLeft:6,
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
				</div>
			</Modal>
		</LayoutAdmin>
	)
}

export default MesClients
