import React, { useEffect, useState } from 'react'
import {getMissionsByCommandeId,cancelMission} from '../../api/commande'
import { useParams,useHistory } from 'react-router'
import { GradientText } from '../../Containers/PasserCommande/PasserCommande.styles'
import Container from '../../Components/LayoutClient/container.component'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import PointsPassageModal from '../../Components/PointsPassages/PointsPassageModal'
import MissionCard from '../../Components/MissionCard/MissionCard'
import ForfaitSupplementaireModal from '../../Components/ForfaitSupplementaire/ForfaitSupplementaireModal'
import AddForfaitSupplementaireModal from '../../Components/ForfaitSupplementaire/AddForfaitSupplementaireModal'
import Logo from '../../assets/img/logo-TPE.png'
import Modal from 'react-modal';
import MissionFilesModal from '../../Components/Files/MissionFilesModal'


function MissionsClient() {
    const  {id}=useParams()
    const history=useHistory()
    const token = useSelector((state) => state.auth.token);

    const [missions, setMissions] = useState(null)
	const [selectedMission,setSelectedMission]=useState(null)
    const [indexNumber,setIndexNumber]=useState(0)

    const [chargementEnCours,setChargementEnCours]=useState(false)
    const [chargement,setChargement]=useState(false)
	const [isOrderCanceled, setIsOrderCanceled ] = useState(false)
	const [loading,setLoading]=useState(false)

	const [modalIsOpenPlace, setModalIsOpenPlace] = useState(false);
	const [modalIsOpenForfait,setModalIsOpenForfait]=useState(false)
    const [modalIsOpenAddForfait,setModalIsOpenAddForfait]=useState(false)
	const [modalIsOpenPayment,setModalIsOpenPayment]=useState(false)
	const [modalIsOpenCanceled, setModalIsOpenCanceled] = useState(false);
    const [modalIsOpenFiles,setModalIsOpenFiles]=useState(false)

    async function fetchMissions(id) {
        setChargementEnCours(true)
        try {
			const res = await getMissionsByCommandeId(id,token)
			setMissions(res)
        } catch (error) {
            toast.error("Une erreur s'est produite", {
                style: {
                    fontFamily: 'Montserrat',
                },
            });
        }finally{
            setChargementEnCours(false)

        }
       

    }
    
	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const success = params.get('success');
		if (success === 'true') {
			setModalIsOpenPayment(true)
			setIsOrderCanceled(false)
		}
		if (success === 'false') {
			setModalIsOpenPayment(true)
			setIsOrderCanceled(true)
		}
    params.delete('success');
    history.replace({
      search: params.toString()
    });
	},[history])
	

	useEffect(() => {
		if (id) {
			fetchMissions(id)
		}
	}, [id,chargement])
   
  return (
    <Container>
        <div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
                <div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<div
							onClick={() => {
								history.push('/commandes/list')
							}}
							style={{
								background: '#51C7F2',
								width: '3rem',
								height: '3rem',
								borderRadius: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								cursor: 'pointer',
							}}
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width={14.017}
								height={14.11}
								viewBox='0 0 14.017 14.11'
								
							>
								<g
									data-name='Group 11287'
									fill='none'
									stroke='#fff'
									strokeWidth={2}
								>
									<path
										data-name='Path 11687'
										d='M16.6 11.818l-6.348 6.349 2.115 2.115 4.232 4.232'
										transform='translate(-8.838 -11.113)'
									/>
									<path
										data-name='Path 11688'
										d='M22.147 11.818l-6.348 6.349 6.348 6.348'
										transform='translate(-8.838 -11.113)'
									/>
								</g>
							</svg>
						</div>

						<div
							style={{
								width: '1rem',
							}}
						/>

						<GradientText marginTop={'unset'}>
							{`Gestion des  missions`.toUpperCase()}
						</GradientText>
					</div>
                    {chargementEnCours ? 
                    <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'white',
                      marginTop:'10rem'
                    }}
                    >
                    <div className='customLoader'></div>	
                    </div> 
                   :
                   <div
                    style={{width:'100%',marginTop:'1rem'
                    }}>
                    {missions?.map((mission, index) => (
                    
		            <MissionCard 
					key={index}
					mission={mission}
					hideAssign
					hideHistoryChauffeur
					hideChauffeur
					onClickPlaces={()=>{
						setSelectedMission(mission)
		                setModalIsOpenPlace(true);
					}} 
					onClickForfaits={()=>{
						setSelectedMission(mission)
						setModalIsOpenForfait(true);
					}}
					onClickAddForfait={()=>{
						setSelectedMission(mission)
						setModalIsOpenAddForfait(true)
					}}
					onClickCancelMission={()=>{
						setSelectedMission(mission)
						setModalIsOpenCanceled(true)
					}}
					onClickFiles={()=>{
						setSelectedMission(mission)
						setIndexNumber(index)
                        setModalIsOpenFiles(true)
					}}
					index={index}  
					setChargement={setChargement}
					
					/>
                   
					
                    ))}
                   </div>
                   }
        </div>
		<PointsPassageModal 
			  isOpen={modalIsOpenPlace} 
			  onClose={() => setModalIsOpenPlace(false)}
			  missionId={selectedMission?._id}
		/>
		<ForfaitSupplementaireModal
			 isOpen={modalIsOpenForfait} 
			 onClose={() => setModalIsOpenForfait(false)}
			 missionId={selectedMission?._id}
			 marge={0}	 
		/>
		<MissionFilesModal
		   isOpen={modalIsOpenFiles}
		   onClose={()=>setModalIsOpenFiles(false)}
		   missionId={selectedMission?._id}
		   index={indexNumber}
		/>
		<AddForfaitSupplementaireModal
		 isOpen={modalIsOpenAddForfait} 
		 onClose={() => setModalIsOpenAddForfait(false)}
		 mission={selectedMission}
		/>
		  <Modal
				isOpen={modalIsOpenPayment}
				onRequestClose={() => setModalIsOpenPayment(false)}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '35rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div>
					<img
					style={{
						width: '10rem',
						backgroundColor: 'grey',
						padding: '2px'
					}}
					alt='logo'
					src={Logo}
				/>
						<h1>{isOrderCanceled ? "Erreur de paiement": "paiement effectué avec succès"}</h1>
					</div>

					<button
						onClick={() => setModalIsOpenPayment(false)}
						style={{
							padding: '1rem 2rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 30,
						}}
					>
						OK
					</button>
				</div>
		  </Modal>
		  <Modal
				isOpen={modalIsOpenCanceled}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					>Annuler Mission</div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>Êtes-vous sûr de vouloir annuler cette  mission ? </p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
					  
					  onClick={async () => {	
						setLoading(true)
						try {
							const  res=await cancelMission(
								selectedMission?.commande?._id,
								selectedMission?._id,
								token
		
							)
							toast.success(
								res.message,
								{
									style: {
										fontFamily: 'Montserrat',
									},
								}
							)
							history.goBack()
						} catch (error) {
							toast.error(
								error.response?.data.message || "Erreur lors de l\'annulation de la mission",
								{
									style: {
										fontFamily: 'Montserrat',
									},
								}
							)
						}finally {
							setLoading(false)
							setModalIsOpenCanceled(false)
							setSelectedMission(null)
						}
						  
						  
						}}
						disabled={loading}

						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
							display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
						}}
					>
					{loading ? (
									<div className='loader'></div>
								) : (
									'Confirmer'
								)}
					</button>
					<button
						onClick={async () => {
							setModalIsOpenCanceled(false)
												
						}}
						style={{
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	      </Modal>

    </Container>
  )
}

export default MissionsClient