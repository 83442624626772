import React, { useEffect, useState } from 'react'
import {
	attributionCommandes,
	getCommandesForTransporter,
	updateRecuCommande,
	refuserCommande,
	checkChauffeur,
	reattributionCommandesChauffeur,
	reattributionCommandesInitChauffeur,
	reattributionCommandesMiseADispoChauffeur

} from '../../api/commande'
import {getValidChauffeursByTransporter } from '../../api/chauffeurs'
import {
	searchCommandesByDateForTransporter,
	searchCommandesByPrixMinMaxForTransporter,
	searchCommandesByIdForTransporter
} from '../../api/transporteurs'

import CourseACourseHorizontal from '../../Components/SingleCommandeHorizontal/CourseACourseHorizontal'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import { GradientText } from '../PasserCommande/PasserCommande.styles'
import LayoutTransporter from '../../Components/LayoutTransporter/LayoutTransporter'
import MiseADispoHorizontal from '../../Components/SingleCommandeHorizontalForTransporteur/MiseADispoHorizontal'
import Modal from 'react-modal'
import Select from 'react-select'
import moment from 'moment'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import { CustomInput } from '../RegisterTransporter/style'
import UltimatePaginationBasic from '../../Components/Pagination/Pagination'
import DetailCommandeModal from '../../Components/DetailCommande/DetailCommandeModal'

const SIZE = 10

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')

function MesTransports() {
	const history = useHistory()
	const [commandes, setCommandes] = useState([])
	const [chargementEnCours, setChargementEnCours] = useState(false);
	const [modalIsOpenTransfert, setModalIsOpenTransfert] = useState(false)
	const [modalIsOpenTransfertEnCours, setModalIsOpenTransfertEnCours] = useState(false)
	const [modalIsOpenTransfertMiseADispoEnCours, setModalIsOpenTransfertMiseADispoEnCours] = useState(false)
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
	const [modalIsOpenRecu, setModalIsOpenRecu] = useState(false)
	const [modalIsOpenRefuser, setModalIsOpenRefuser] = useState(false)
	const [modalIsOpenAttribuer, setModalIsOpenAttribuer] = useState(false)
	const [modalIsOpenCheck,setModalIsOpenCheck]=useState(false)
	const [modalIsOpenDetail,setModalIsOpenDetail]=useState(false)
	const [chauffeurs, setChauffeurs] = useState([])
	const [message,setMessage]=useState('')
	const [page, setPage] = useState(1)
	const [totalPages,setTotalPages]=useState(1)
	const  [messageCheck,setMessageCheck]=useState('')
	const [selectedCommandes, setSelectedCommandes] = useState([])
    const [selectedChauffeur, setSelectedChauffeur] = useState(null)
	const [loading,setLoading]=useState(false)
	const [distance, setDistance] = useState('');
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
	const [isConfirmationOpenAttribuer, setIsConfirmationOpenAttribuer] = useState(false)
	const [isConfirmationOpenEnCours, setIsConfirmationOpenEnCours] = useState(false)
	const [isConfirmationOpenMiseADispoEnCours, setIsConfirmationOpenMiseADispoEnCours] = useState(false)
    const [selectedCommande,setSelectedCommande]=useState(null)
	const transporter = useSelector((state) => state.auth.user)
	const token = useSelector((state) => state.auth.token);

	const [searchCriteria, setSearchCriteria] = useState('id')
	const [range, setRange] = useState([new Date(), new Date()])
	const [min, setMin] = useState(0)
	const [max, setMax] = useState(300)
	const [searchQueryId, setSearchQueryId] = useState('')
	const [commande,setCommande]=useState(null)
	const [jour,setJour]=useState(null)
	const [heure,setHeure]=useState(null)
	const [isSearching, setIsSearching] = useState(false); 
	const [selectedOption, setSelectedOption] = useState({
		value: 'id', label: "Recherche par l'identifiant " 
	 });
	 const handleChange = (selectedOption) => {
	   setSelectedOption(selectedOption);
	   setSearchCriteria(selectedOption.value)
	 };
	useEffect(() => {
		async function fetchChauffeurs() {
			const res = await getValidChauffeursByTransporter(user._id,token)
			setChauffeurs(res)
			
		}

		fetchChauffeurs()
	}, [])

	const user = useSelector((state) => state.auth.user)

	const fetchCommandes = async (page) => {
		setChargementEnCours(true);
		console.log(isSearching)
		try {
			let res;
			if (isSearching) {
				if (searchCriteria === 'id') {
					res = await searchCommandesByIdForTransporter(searchQueryId, transporter._id,'commande', token,page,SIZE);
				} else if (searchCriteria === 'prix') {
					res = await searchCommandesByPrixMinMaxForTransporter(min, max, transporter._id, 'commande', token, page, SIZE);
				} else if (searchCriteria === 'date') {
					let minDate = moment(range[0]).format();
					let maxDate = moment(range[1]).format();
					res = await searchCommandesByDateForTransporter(minDate, maxDate, transporter._id, 'commande', token, page, SIZE);
				}
			} else {
				res = await getCommandesForTransporter(user._id, token, page, SIZE);
			}
			setCommandes(res.commandes);
			setTotalPages(res.totalPages);
		} catch (error) {
			console.log('error', error);
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			});
		} finally {
			setChargementEnCours(false);
		}
	};
	const resetData = async () => {
		setIsSearching(false); 
		setSearchQueryId(''); 
		setSearchCriteria("id")
		setSelectedOption({
			value: 'id', label: "Recherche par l'identifiant " 
		  })
		setPage(1);
	};
	
	
	const handleSearch = () => {
		setIsSearching(true); 
		setPage(1); 
		if(isSearching){
			fetchCommandes(1); 

		}
	};
  
	useEffect(() => {
		fetchCommandes(page); 
	}, [page,isSearching])
	

	return (
		<LayoutTransporter>
			<div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
				}}
			>
				<GradientText>MES TRANSPORTS</GradientText>
				<span
					style={{
						fontFamily: 'Montserrat',
						color: '#262626',
						marginTop: '0.5rem',
						textAlign: 'center',
					}}
				>
					Ici retrouver tous les transports qui vous ont été affecté
					par la plate-forme,
					<br /> aussi vous trouverez les transports que vous avez
					accepté sur la plate-forme
				</span>

				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						minHeight: '60vh',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						marginTop: '1rem',
						paddingLeft: '.5rem',
						paddingRight: '.5rem',
					}}
				>
					<div
						style={{
							width: '100%',
							fontFamily: 'Montserrat',
							paddingLeft: '1rem',
							paddingRight: '1rem',
							marginBottom: '2rem',
							marginTop: '1rem',
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<div
							style={{
								width: '350px',
								fontFamily: 'Montserrat',
								marginRight: '1rem',
							}}
						>
							<Select
								isSearchable={false}
								options={[
									{ value: 'id', label: "Recherche par l'identifiant " },
									{ value: 'prix', label: 'Prix' },
									{ value: 'date', label: 'Date du Transports' },
								]}
								styles={{
									option: (provided, state) => ({
										...provided,
										fontFamily: 'Montserrat',
									}),
									control: (provided, state) => ({
										...provided,
										height: '3rem',
										minHeight: 'fit-content',
									}),
									valueContainer: (base) => ({
										...base,
										maxHeight: '3rem',
									}),
								}}
								value={selectedOption}
							    onChange={handleChange}
								
								menuPortalTarget={document.querySelector(
									'body'
								)}
							/>
						</div>
						{searchCriteria === 'id' ? (
						<input
							style={{
								padding: '0.4rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								height: '3rem',
								width: '20rem',
								fontFamily: 'Montserrat',
							}}
							value={searchQueryId}
							onChange={(e) => setSearchQueryId(e.target.value)}
							placeholder='Recherche'
						/>
					) : null}
						{searchCriteria === 'prix' ? (
							<>
								<input
									type='number'
									style={{
										padding: '0.4rem',
										borderRadius: 5,
										border: '1px solid #E4EAF0',
										height: '3rem',
										width: '20rem',
										fontFamily: 'Montserrat',
										marginRight: '1rem',
									}}
									value={min}
									onChange={(e) => {
										setMin(Number(e.target.value))
									}}
									placeholder='Prix min'
								/>
								<input
									type='number'
									style={{
										padding: '0.4rem',
										borderRadius: 5,
										border: '1px solid #E4EAF0',
										height: '3rem',
										width: '20rem',
										fontFamily: 'Montserrat',
									}}
									value={max}
									onChange={(e) => {
										setMax(Number(e.target.value))
									}}
									placeholder='Prix max'
								/>
							</>
						) : null}

						{searchCriteria === 'date' ? (
							<DateRangePicker
								onChange={setRange}
								value={range}
							/>
						) : null}

						<button
							onClick={handleSearch}
							style={{
								padding: '0.6rem 1rem',
								height: '3rem',
								background: '#C9D5F7',
								border: 'none',
								cursor: 'pointer',
								color: '#1C63C5',
								borderRadius: 7,
								//marginTop: 20,
								marginLeft: '1rem',
								fontFamily: 'Montserrat',
								fontWeight: 'bold',
							}}
						>
							Rechercher
						</button>

						<button
							onClick={resetData}
							style={{
								padding: '0.6rem 1rem',
								height: '3rem',
								background: '#C9D5F7',
								border: 'none',
								cursor: 'pointer',
								color: '#1C63C5',
								borderRadius: 7,
								marginRight: '1rem',
								marginLeft: '1rem',
								fontFamily: 'Montserrat',
								fontWeight: 'bold',
							}}
						>
							Effacer
						</button>
					</div>
					{chargementEnCours ?
                    <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'white',
                      marginLeft:"35rem",
                      marginTop:"10rem"
                    }}
                    >
                    <div className='customLoader'></div>	
                    </div>
					:
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'wrap',
							width: '100%',
							height: '100%',
							alignItems: 'flex-start',
							marginBottom: '2rem',
						}}
					>
						{ commandes.length === 0 ? 
						<span
						style={{
							fontFamily: 'Montserrat',
							color: '#262626',
							marginTop: '0.5rem',
							textAlign: 'center',
							paddingLeft:"400px"
						}}

					>
						Il n'y a pas des commandes à afficher
					</span>
						:commandes.map((commande) => {
							if (commande.type_commande === 'course-a-course') {
								return (
									<div
										key={commande._id}
										style={{
											display: 'flex',
											flexDirection: 'row',
											width: '100%',
										}}
									>
										

										<CourseACourseHorizontal
											commande={commande}
											hidecancel
											hidefacture
											hideStatus
											hideUpdate
											hideTransfert={false}
											onClickDetails={() => {
												history.push(
													`/transports/${commande._id}`
												)
											}}
											onClickSignaler={() => {
												history.push(
													`/transporter/transports/${commande._id}/signaler`
													
												)
											}}
											onClickRecu={async () => {
												setSelectedCommande(commande._id)
												setModalIsOpenRecu(true)
											}}
											onClickRefuser={async () => {
												setSelectedCommande(commande)
												setModalIsOpenRefuser(true)																		
											}}
											onClickAttribuer={async () => {
												if(!commande.recu){
													toast.error(
														" Veuillez confirmer cette commande en cliquant sur le bouton Recu avant de l'attribuer au chauffeur",
														{
															style: {
																fontFamily: 'Montserrat',
															},
														}
													)
												}
												else{
													setModalIsOpen(true)
												    setSelectedCommandes([
													commande._id,
												])
												}
												
											}}
											
											onClickTransferer={async () => {
												if(commande.statut==='init'){
													setModalIsOpenTransfert(true)
													setSelectedCommandes([
														commande._id,
													])
													}
													if(commande.statut==='en-cours'){
														setCommande(commande)
														setModalIsOpenTransfertEnCours(true)
														
													}
											}
											}
											onClickHistoriqueChauffeur={()=>{
												history.push(
													`/transports/historique-chauffeur/${commande._id}`
												)
											}

											}
										/>
									</div>
								)
							} else {
								return (
									<div
										key={commande._id}
										style={{
											display: 'flex',
											flexDirection: 'row',
											width: '100%',
										}}
									>
										
										<MiseADispoHorizontal
											commande={commande}
											hidecancel
											hidefacture
											hideStatus
											hideUpdate
											hideTransfert={false}
											onClickDetails={() => {
												setSelectedCommande(commande._id)
										        setModalIsOpenDetail(true)
											}}
											onClickSignaler={() => {
												history.push(
                                                    `/transports/${commande._id}/single`
												)
											}}
											onClickRecu={async () => {
												setSelectedCommande(commande._id)
												setModalIsOpenRecu(true)
											}}
											onClickRefuser={async () => {
												setSelectedCommande(commande._id)
												setModalIsOpenRefuser(true)
											}}
											onClickAttribuer={async () => {
                                                if(!commande.recu){
													toast.error(
														" Veuillez confirmer cette commande en cliquant sur le bouton Recu avant de l'attribuer au chauffeur",
														{
															style: {
																fontFamily: 'Montserrat',
															},
														}
													)
												}else{
												history.push(
													`/transporter/transports/missions/${commande._id}`
												)
											    }
												
											
											}}
											
											onClickHistoriqueChauffeur={()=>{
												history.push(
													`/transports/historique-chauffeur/${commande._id}`
												)
											}

											}
										/>
									</div>
								)
							}
						})}
					</div>
                    }
				</div>
				<div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<UltimatePaginationBasic
							currentPage={page}
							totalPages={totalPages}
							onChange={(newPage) => setPage(newPage)}
						/>
					</div>
			</div>
			<DetailCommandeModal 
			  isOpen={modalIsOpenDetail} 
			  onClose={() => setModalIsOpenDetail(false)}
			  commandeId={selectedCommande}
			/>
			<Modal
				isOpen={modalIsOpen}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						background: '#E4EAF0',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '25rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<span
						style={{
							color: '#51C7F2',
							fontWeight: 'bold',
							fontSize: 30,
							marginBottom: '2rem',
						}}
					>
						Attribuer un transport
					</span>
					<div
						style={{
							marginBottom: '2rem',
						}}
					>
						Veuillez sélectionner le chauffeur <br /> que vous
						voulez affecter à ce transport
					</div>

					<div
						style={{
							width: '80%',
						}}
					>
						<Select
							options={chauffeurs.map((c) => ({
								value: c._id,
								label: `${c.first_name} ${c.last_name}`,
							}))}
							styles={{
								option: (provided, state) => ({
									...provided,
									fontFamily: 'Montserrat',
								}),
							}}
							menuPortalTarget={document.querySelector('body')}
							onChange={(x) => setSelectedChauffeur(x.value)}
						/>
					</div>
					<div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '1rem',
                            marginTop: '1rem',
                        }}
                    >
						<div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
							<button
						onClick={ () => {
							if(!selectedChauffeur){
								toast.error(
									'Il faut séléctionner un chauffeur',
									{
										style: {
											fontFamily: 'Montserrat',
										},
									}
								)

							}else{
								setIsConfirmationOpenAttribuer(true)
								setModalIsOpen(false)
							}


						}}
						style={{
							padding: '0.5rem 2rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 30,
							fontSize: 15,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Attribuer
					</button>
					<button
                    onClick={() => {
                                    setModalIsOpen(false);
									setSelectedCommandes([])
									setSelectedChauffeur(null)
                                }}
								style={{
									marginLeft:"1rem",
									padding: '1rem 2rem',
									background: 'red',
									border: 'none',
									cursor: 'pointer',
									color: 'white',
									borderRadius: 7,
									marginTop: 30,
									fontSize: 15,
									fontFamily: 'Montserrat',
									fontWeight: 'bold',
								}}
                            >
                                Annuler
                            </button>
					</div>
					</div>
					
				</div>
			</Modal>
			<Modal
      isOpen={isConfirmationOpenAttribuer}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.45)',
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: 19,
          background: '#E4EAF0',
        },
      }}
      contentLabel="Confirmation Modal"
    >
      <div
        style={{
          width: '25rem',
          fontFamily: 'Montserrat',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: 15,
          textAlign: 'center',
        }}
      >
        <span
          style={{
            color: '#51C7F2',
            fontWeight: 'bold',
            fontSize: 30,
            marginBottom: '2rem',
          }}
        >
          Confirmation
        </span>
        <div
          style={{
            marginBottom: '2rem',
          }}
        >
          Êtes-vous sûr de vouloir attribuer ce transport ?
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <button
            onClick={async()=>{
				setLoading(true)
				try{
				const res=await  checkChauffeur(selectedChauffeur,token,selectedCommandes)
				if(res.success===true){
					await attributionCommandes(
						selectedCommandes,
						selectedChauffeur,
						token
					)
					toast.success("L'attribution a été effectuée avec succès", {
						style: {
						  fontFamily: 'Montserrat',
						},
					  });

				}else{
				 setIsConfirmationOpenAttribuer(false)
				 setMessageCheck(res.message)
				 setModalIsOpenCheck(true)
				}
				}catch(error){
					if (error.response && error.response.status === 403) {
						setMessage(error.response.data.error)
						setModalIsOpenAttribuer(true)	
					}else{
						toast.error("Une erreur s'est produite", {
							style: {
								fontFamily: 'Montserrat',
							},
						});
					}

				}finally{
					setLoading(false)
					setIsConfirmationOpenAttribuer(false)
					await fetchCommandes(page)
					setSelectedCommandes([])
					setSelectedChauffeur(null)
				}

			}}
			disabled={loading}
            style={{
			  padding: '1rem 2rem',
              background: '#50F5A9',
              border: 'none',
              cursor: 'pointer',
              color: 'white',
              borderRadius: 7,
              margin: '0 1rem',
              fontSize: 15,
              fontFamily: 'Montserrat',
              fontWeight: 'bold',
            }}
          >

			{loading ? (
                                    <div className='loader'></div>
                                ) : (
                                     'Oui'
                                )}
          </button>
          <button
            onClick={()=>{
				setIsConfirmationOpenAttribuer(false)
				setSelectedCommandes([])
				setSelectedChauffeur(null)
			}}
            style={{
              padding: '1rem 2rem',
              background: 'red',
              border: 'none',
              cursor: 'pointer',
              color: 'white',
              borderRadius: 7,
              margin: '0 1rem',
              fontSize: 15,
              fontFamily: 'Montserrat',
              fontWeight: 'bold',
            }}
          >
            Non
          </button>
        </div>
      </div>
    </Modal>
			<Modal
				isOpen={modalIsOpenTransfert}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						background: '#E4EAF0',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '25rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<span
						style={{
							color: '#51C7F2',
							fontWeight: 'bold',
							fontSize: 30,
							marginBottom: '2rem',
						}}
					>
						Transférer un transport
					</span>
					<div
						style={{
							marginBottom: '2rem',
						}}
					>
						Veuillez sélectionner le chauffeur <br /> que vous
						voulez transférer à ce transport
					</div>

					<div
						style={{
							width: '80%',
						}}
					>
						<Select
							options={chauffeurs.map((c) => ({
								value: c._id,
								label: `${c.first_name} ${c.last_name}`,
							}))}
							styles={{
								option: (provided, state) => ({
									...provided,
									fontFamily: 'Montserrat',
								}),
							}}
							menuPortalTarget={document.querySelector('body')}
							onChange={(x) => setSelectedChauffeur(x.value)}
						/>
					</div>
					<div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '1rem',
                            marginTop: '1rem',
                        }}
                    >
						<div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
					<button
						onClick={ () => {
							if(!selectedChauffeur){
								toast.error(
									'Il faut séléctionner un chauffeur',
									{
										style: {
											fontFamily: 'Montserrat',
										},
									}
								)

							}else{
								setIsConfirmationOpen(true)
								setModalIsOpenTransfert(false)
							}

						}}
						style={{
							padding: '0.5rem 2rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 30,
							fontSize: 15,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Transférer
					</button>
					<button
                    onClick={() => {
                                    setModalIsOpenTransfert(false);
									setCommande(null)
									setSelectedChauffeur(null)
                                }}
								style={{
									marginLeft:"1rem",
									padding: '1rem 2rem',
									background: 'red',
									border: 'none',
									cursor: 'pointer',
									color: 'white',
									borderRadius: 7,
									marginTop: 30,
									fontSize: 15,
									fontFamily: 'Montserrat',
									fontWeight: 'bold',
								}}
                            >
                                Annuler
                            </button>
					</div>
					</div>
				</div>
			</Modal>

			<Modal
      isOpen={isConfirmationOpen}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.45)',
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: 19,
          background: '#E4EAF0',
        },
      }}
      contentLabel="Confirmation Modal"
    >
      <div
        style={{
          width: '25rem',
          fontFamily: 'Montserrat',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: 15,
          textAlign: 'center',
        }}
      >
        <span
          style={{
            color: '#51C7F2',
            fontWeight: 'bold',
            fontSize: 30,
            marginBottom: '2rem',
          }}
        >
          Confirmation
        </span>
        <div
          style={{
            marginBottom: '2rem',
          }}
        >
          Êtes-vous sûr de vouloir transférer ce transport ?
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <button
            onClick={async()=>{
				setLoading(true)
				try{
				const res=await  checkChauffeur(selectedChauffeur,token,selectedCommandes)
				if(res.success===true){
					await reattributionCommandesInitChauffeur(
						selectedCommandes,
						transporter._id,
						selectedChauffeur,
						token
					)
					toast.success('Le transfert a été effectué avec succès', {
						style: {
						  fontFamily: 'Montserrat',
						},
					  });

				}else{
				 setIsConfirmationOpen(false)
				 setMessageCheck(res.message)
				 setModalIsOpenCheck(true)
				}
				}catch(error){
					if (error.response && error.response.status === 403) {
						setMessage(error.response.data.error)
						setIsConfirmationOpen(false)
						setModalIsOpenAttribuer(true)	
					}else{
						toast.error("Une erreur s'est produite", {
							style: {
								fontFamily: 'Montserrat',
							},
						});
					}

				}finally{
					setLoading(false)
					setIsConfirmationOpen(false)
					await fetchCommandes(page)
					setSelectedCommandes([])
					setSelectedChauffeur(null)
				}
			}}
			disabled={loading}
            style={{
			  padding: '1rem 2rem',
              background: '#50F5A9',
              border: 'none',
              cursor: 'pointer',
              color: 'white',
              borderRadius: 7,
              margin: '0 1rem',
              fontSize: 15,
              fontFamily: 'Montserrat',
              fontWeight: 'bold',
            }}
          >

			{loading ? (
                                    <div className='loader'></div>
                                ) : (
                                     'Oui'
                                )}
          </button>
          <button
            onClick={()=>{
				setIsConfirmationOpen(false)
				setSelectedCommandes([])
				setSelectedChauffeur(null)
			}}
            style={{
				padding: '1rem 2rem',
				background: 'red',
				border: 'none',
				cursor: 'pointer',
				color: 'white',
				borderRadius: 7,
				margin: '0 1rem',
				fontSize: 15,
				fontFamily: 'Montserrat',
				fontWeight: 'bold',
            }}
          >
            Non
          </button>
        </div>
      </div>
    </Modal>
	<Modal
      isOpen={isConfirmationOpenEnCours}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.45)',
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: 19,
          background: '#E4EAF0',
        },
      }}
      contentLabel="Confirmation Modal"
    >
		<div
        style={{
          width: '25rem',
          fontFamily: 'Montserrat',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: 15,
          textAlign: 'center',
        }}
      >
		 <span
          style={{
            color: '#51C7F2',
            fontWeight: 'bold',
            fontSize: 30,
            marginBottom: '2rem',
          }}
        >
          Confirmation
        </span>
		<div
          style={{
            marginBottom: '2rem',
          }}
        >
          Êtes-vous sûr de vouloir transférer ce transport ?
        </div>
		<div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
			<button
            onClick={async()=>{
				setLoading(true)
				try{
				const res=await  checkChauffeur(selectedChauffeur,token,selectedCommandes)
				if(res.success===true){
					await reattributionCommandesChauffeur(
						commande._id,
						transporter._id,
						selectedChauffeur,
						distance,
						token
					)
					toast.success('Le transfert a été effectué avec succès', {
						style: {
						  fontFamily: 'Montserrat',
						},
					  });

				}else{
				 setIsConfirmationOpenEnCours(false)
				 setMessageCheck(res.message)
				 setModalIsOpenCheck(true)
				}
				}catch(error){
					if (error.response && error.response.status === 403) {
						setMessage(error.response.data.error)
						setIsConfirmationOpenEnCours(false)
						setModalIsOpenAttribuer(true)	
					}else{
						toast.error("Une erreur s'est produite", {
							style: {
								fontFamily: 'Montserrat',
							},
						});
					}

				}finally{
					setDistance('')
					setLoading(false)
					setIsConfirmationOpenEnCours(false)
					await fetchCommandes(page)
					setCommande(null)
					setSelectedChauffeur(null)
				}
			}}
			disabled={loading}
            style={{
              padding: '1rem 2rem',
              background: '#50F5A9',
              border: 'none',
              cursor: 'pointer',
              color: 'white',
              borderRadius: 7,
              margin: '0 1rem',
              fontSize: 15,
              fontFamily: 'Montserrat',
              fontWeight: 'bold',
            }}
          >

			{loading ? (
                                    <div className='loader'></div>
                                ) : (
                                     'Oui'
                                )}
          </button>
		  <button
            onClick={()=>{
				setIsConfirmationOpenEnCours(false)
				setCommande(null)
				setSelectedChauffeur(null)
			}}
            style={{
              padding: '1rem 2rem',
              background: 'red',
              border: 'none',
              cursor: 'pointer',
              color: 'white',
              borderRadius: 7,
              margin: '0 1rem',
              fontSize: 15,
              fontFamily: 'Montserrat',
              fontWeight: 'bold',
            }}
          >
            Non
          </button>
		</div>
	  </div>
	</Modal>

	<Modal
				isOpen={modalIsOpenTransfertEnCours}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						background: '#E4EAF0',

					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '25rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<span
						style={{
							color: '#51C7F2',
							fontWeight: 'bold',
							fontSize: 30,
							marginBottom: '2rem',
						}}
					>
						Transférer un transport
					</span>
					<div
						style={{
							marginBottom: '2rem',
						}}
					>
						Veuillez sélectionner le chauffeur <br /> que vous
						voulez transférer à ce transport
					</div>
					<div
						style={{
							width: '80%',
						}}
					>
						<Select
							options={chauffeurs.map((c) => ({
								value: c._id,
								label: `${c.first_name} ${c.last_name}`,
							}))}
							styles={{
								option: (provided, state) => ({
									...provided,
									fontFamily: 'Montserrat',
								}),
							}}
							menuPortalTarget={document.querySelector('body')}
							onChange={(x) => setSelectedChauffeur(x.value)}
						/>
					</div>
					<div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '75%',
                                marginRight: '1rem',
                            }}
                        >
							 <span
                                style={{
                                    marginBottom: '1rem',
									marginTop:'1rem',
                                    color: 'black',
                                    fontSize: 14,
                                }}
                            >
                               Veuillez saisir la distance parcourue
                            </span>
							<CustomInput
                                type='number'
                                placeholder=''
                                min={1}
								value={distance}
								onChange={(e)=>setDistance(e.target.value)}

                            />
						</div>
						<div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '1rem',
                            marginTop: '1rem',
                        }}
                    >
						<div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
							<button
						onClick={ () => {
							if (!selectedChauffeur) {
								toast.error('Il faut séléctionner un chauffeur', {
									style: {
										fontFamily: 'Montserrat',
									},
								});
							} else if (distance === '') {
								toast.error('Veuillez saisir la distance parcourue', {
									style: {
										fontFamily: 'Montserrat',
									},
								});
							} else if (Number(distance) > commande.distance) {
								toast.error(`La distance parcourue ne doit pas dépasser la distance de la commande ${commande.distance} km`, {
									style: {
										fontFamily: 'Montserrat',
									},
								});
							} else {
								setIsConfirmationOpenEnCours(true);
								setModalIsOpenTransfertEnCours(false);
							}

						}}
						style={{
							padding: '1rem 2rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 30,
							fontSize: 15,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Transférer
					</button>
					<button
                    onClick={() => {
						setModalIsOpenTransfertEnCours(false);
						setCommande(null)
						setDistance('')
						setSelectedChauffeur(null)
                                }}
								style={{
									marginLeft:"1rem",
									padding: '1rem 2rem',
									background: 'red',
									border: 'none',
									cursor: 'pointer',
									color: 'white',
									borderRadius: 7,
									marginTop: 30,
									fontSize: 15,
									fontFamily: 'Montserrat',
									fontWeight: 'bold',
								}}
                            >
                                Annuler
                            </button>
						</div>
					</div>
				</div>
			</Modal>

           



			<Modal
    isOpen={confirmationModalIsOpen}
    onRequestClose={() => {}}
    style={{
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '20rem', // Taille réduite
            borderRadius: 19,
        },
    }}
    contentLabel='Example Modal'
>
    <div
        style={{
            fontFamily: 'Montserrat',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 15,
            textAlign: 'center',
        }}
    >
        <div
            style={{
                marginTop: 20,
            }}
        >
	 Certaines commandes n'ont pas été confirmées. Veuillez les confirmer avant de les attribuer à un chauffeur.

        </div>

        <button
            onClick={() => {
				setConfirmationModalIsOpen(false)
            }}
            style={{
                padding: '0.5rem 1rem',
                background: '#50F5A9',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
                borderRadius: 7,
                marginTop: 30,
            }}
        >
            OK
        </button>
    </div>
           </Modal>
		   <Modal
    isOpen={modalIsOpenAttribuer}
    onRequestClose={() => {}}
    style={{
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '20rem', // Taille réduite
            borderRadius: 19,
        },
    }}
    contentLabel='Example Modal'
>
    <div
        style={{
            fontFamily: 'Montserrat',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 15,
            textAlign: 'center',
        }}
    >
        <div
            style={{
                marginTop: 20,
            }}
        >
        {message}
		 </div>

        <button
            onClick={() => {
                setModalIsOpenAttribuer(false);
            }}
            style={{
                padding: '0.5rem 1rem',
                background: '#50F5A9',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
                borderRadius: 7,
                marginTop: 30,
            }}
        >
            OK
        </button>
    </div>
           </Modal>
		   
		   <Modal
				isOpen={modalIsOpenTransfertMiseADispoEnCours}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						background: '#E4EAF0',

					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '25rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<span
						style={{
							color: '#51C7F2',
							fontWeight: 'bold',
							fontSize: 30,
							marginBottom: '2rem',
						}}
					>
						Transférer un transport
					</span>
					<div
						style={{
							marginBottom: '2rem',
						}}
					>
						Veuillez sélectionner le chauffeur <br /> que vous
						voulez transférer à ce transport
					</div>

					<div
						style={{
							width: '80%',
						}}
					>
						<Select
							options={chauffeurs.map((c) => ({
								value: c._id,
								label: `${c.first_name} ${c.last_name}`,
							}))}
							styles={{
								option: (provided, state) => ({
									...provided,
									fontFamily: 'Montserrat',
								}),
							}}
							menuPortalTarget={document.querySelector('body')}
							onChange={(x) => setSelectedChauffeur(x.value)}
						/>
					</div>
					<div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '80%',
                                marginRight: '1rem',
								marginTop:'1rem'
                            }}
                        >
                            <span
                                style={{
                                    marginBottom: '1rem',
                                    color: 'black',
                                    fontSize: 14,
                                }}
                            >
                              Veuillez sélectionner le jour de la commande
                            </span>
                            <Select
                            value={jour}
                            onChange={(selectedOption) => setJour(selectedOption)}
                            options={Array.from({ length: commande?.nb_jours }, (v, i) => ({
								value: i + 1,
								label: `${i + 1}`
							}))}

						   />

                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginRight: '1rem',
                            }}
                        >
                            <span
                                style={{
                                    marginBottom: '1rem',
                                    color: 'black',
                                    fontSize: 14,
									textAlign: 'center',
                                }}
                            >
                               Veuillez sélectionner le nombre d'heures parcourues
                            </span>

							<Select
                            value={heure}
                            onChange={(selectedOption) => setHeure(selectedOption)}
                            options={ Array.from({ length: 24 }, (v, i) => ({
								value: i,
								label: `${i}`
							}))}
                            />
                        </div>

					<div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '1rem',
                            marginTop: '1rem',
                        }}
                    >
						<div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
					<button
						onClick={ () => {
							if(!selectedChauffeur){
								toast.error(
									'Il faut séléctionner un chauffeur',
									{
										style: {
											fontFamily: 'Montserrat',
										},
									}
								)

							}
							else if(!jour){
								toast.error(
									'Il faut séléctionner un jour',
									{
										style: {
											fontFamily: 'Montserrat',
										},
									}
								)
							}else if(!heure){
								toast.error(
									"Il faut séléctionner  le nombre d'heures parcourues",
									{
										style: {
											fontFamily: 'Montserrat',
										},
									}
								)

							}
							else{
								setIsConfirmationOpenMiseADispoEnCours(true)
								setModalIsOpenTransfertMiseADispoEnCours(false)
							}

						}}
						style={{
							padding: '1rem 2rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 30,
							fontSize: 15,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Transférer
					</button>
					<button
                    onClick={() => {
						            setModalIsOpenTransfertMiseADispoEnCours(false);
								    setCommande(null)
									setSelectedChauffeur(null)
									setHeure(null)
									setJour(null)
                                }}
								style={{
									marginLeft:"1rem",
									padding: '1rem 2rem',
									background: 'red',
									border: 'none',
									cursor: 'pointer',
									color: 'white',
									borderRadius: 7,
									marginTop: 30,
									fontSize: 15,
									fontFamily: 'Montserrat',
									fontWeight: 'bold',
								}}
                            >
                                Annuler
                            </button>
					</div>
					</div>
				</div>
			</Modal>		
		  
		   <Modal
    isOpen={modalIsOpenCheck}
    onRequestClose={() => {}}
    style={{
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '20rem', // Taille réduite
            borderRadius: 19,
        },
    }}
    contentLabel='Example Modal'
>
    <div
        style={{
            fontFamily: 'Montserrat',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 15,
            textAlign: 'center',
        }}
    >
        <div
            style={{
                marginTop: 20,
            }}
        >
			{messageCheck}
		 </div>

        <button
            onClick={() => {
                setModalIsOpenCheck(false);
            }}
            style={{
                padding: '0.5rem 1rem',
                background: '#50F5A9',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
                borderRadius: 7,
                marginTop: 30,
            }}
        >
            OK
        </button>
    </div>
           </Modal>
		   <Modal
      isOpen={isConfirmationOpenMiseADispoEnCours}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.45)',
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: 19,
          background: '#E4EAF0',
        },
      }}
      contentLabel="Confirmation Modal"
    >
      <div
        style={{
          width: '25rem',
          fontFamily: 'Montserrat',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: 15,
          textAlign: 'center',
        }}
      >
        <span
          style={{
            color: '#51C7F2',
            fontWeight: 'bold',
            fontSize: 30,
            marginBottom: '2rem',
          }}
        >
          Confirmation
        </span>
        <div
          style={{
            marginBottom: '2rem',
          }}
        >
          Êtes-vous sûr de vouloir transférer ce transport ?
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <button
            onClick={async()=>{
				setLoading(true)
				try{
				const res=await  checkChauffeur(selectedChauffeur,token,selectedCommandes)
				if(res.success===true){
					await reattributionCommandesMiseADispoChauffeur(
						commande,
						transporter._id,
						selectedChauffeur,
						jour,
						heure,
						token
					)
					toast.success('Le transfert a été effectué avec succès', {
						style: {
						  fontFamily: 'Montserrat',
						},
					  });

				}else{
				 setIsConfirmationOpenMiseADispoEnCours(false)
				 setMessageCheck(res.message)
				 setModalIsOpenCheck(true)
				}
				}catch(error){
					if (error.response && error.response.status === 403) {
						setMessage(error.response.data.error)
						setIsConfirmationOpenMiseADispoEnCours(false)
						setModalIsOpenAttribuer(true)	
					}else{
						toast.error("Une erreur s'est produite", {
							style: {
								fontFamily: 'Montserrat',
							},
						});
					}

				}finally{
					setDistance('')
					setLoading(false)
					setIsConfirmationOpenMiseADispoEnCours(false)
					await fetchCommandes(page)
					setCommande(null)
					setSelectedChauffeur(null)
				}
			}}
			disabled={loading}
            style={{
              padding: '1rem 2rem',
              background: '#50F5A9',
              border: 'none',
              cursor: 'pointer',
              color: 'white',
              borderRadius: 7,
              margin: '0 1rem',
              fontSize: 15,
              fontFamily: 'Montserrat',
              fontWeight: 'bold',
            }}
          >

			{loading ? (
                                    <div className='loader'></div>
                                ) : (
                                     'Oui'
                                )}
          </button>
          <button
            onClick={()=>{
				setIsConfirmationOpenMiseADispoEnCours(false)
				setSelectedChauffeur(null)
				setCommande(null)
				setHeure(null)
		        setJour(null)
			}}
            style={{
              padding: '1rem 2rem',
              background: 'red',
              border: 'none',
              cursor: 'pointer',
              color: 'white',
              borderRadius: 7,
              margin: '0 1rem',
              fontSize: 15,
              fontFamily: 'Montserrat',
              fontWeight: 'bold',
            }}
          >
            Non
          </button>
        </div>
      </div>
    </Modal>
		   
	<Modal
				isOpen={modalIsOpenRefuser}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel=''
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					> Refuser commande</div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>Êtes-vous sûr de vouloir refuser cette commande ?</p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
					
						onClick={async () => {	
							setLoading(true)
							   try {
								await refuserCommande(token,selectedCommande)
								toast.success("La commande a été refusée avec succès", {
									style: {
										fontFamily: 'Montserrat',
									},
								})
							   } catch (error) {
								if (error.response && error.response.status === 403) {
									toast.error(error.response.data.error, {
										style: {
											fontFamily: 'Montserrat',
										},
									});	
								}else{
									toast.error("Une erreur s'est produite", {
										style: {
											fontFamily: 'Montserrat',
										},
									});
								}
							   }finally {
								setSelectedCommande(null)
								setLoading(false)
								setModalIsOpenRefuser(false)
								await fetchCommandes(page)

							}
							  
                              
                            }}

						disabled={loading}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
							display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
						}}
					>
					{loading ? (
									<div className='loader'></div>
								) : (
									'Confirmer'
								)}
					</button>
					<button
						onClick={async () => {
							setModalIsOpenRefuser(false)
												
						}}
						style={{
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	</Modal>	
	<Modal
				isOpen={modalIsOpenRecu}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Confirmer acceptation commande'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					>Confirmer acceptation commande</div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>Êtes-vous sûr de vouloir confirmer l'acceptation de cette commande ?</p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
					
						onClick={async () => {	
							setLoading(true)
							   try {
								await updateRecuCommande(selectedCommande,token)								
								toast.success("La commande a été acceptée avec succès", {
									style: {
										fontFamily: 'Montserrat',
									},
								})
							   } catch (error) {
								if (error.response && error.response.status === 403) {
						           toast.error(error.response.data.error, {
										style: {
											fontFamily: 'Montserrat',
										},
									});
								}else{
									toast.error("Une erreur s'est produite", {
										style: {
											fontFamily: 'Montserrat',
										},
									});
								}
							   }finally {
								setSelectedCommande(null)
								setLoading(false)
								setModalIsOpenRecu(false)
								await fetchCommandes(page)

							}
							  
                              
                            }}

						disabled={loading}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
							display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
						}}
					>
					{loading ? (
									<div className='loader'></div>
								) : (
									'Confirmer'
								)}
					</button>
					<button
						onClick={async () => {
							setModalIsOpenRecu(false)
												
						}}
						style={{
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	</Modal>	
		</LayoutTransporter>
	)
}

export default MesTransports
