import React from 'react'
import Footer from '../../Components/Footer/footer.component'
import CommonHeader from '../../Components/CommonHeader/common-header.component'
import { useForm } from 'react-hook-form'
import { resetPassword } from '../../api/auth'
import { useParams,useHistory } from 'react-router-dom';
import toast from 'react-hot-toast'
function ResetPassword() {
    const { register, handleSubmit,formState: { errors, isValid, isDirty }  } = useForm()
    const history=useHistory()
    const {id}=useParams()
    const onSubmit = async (data) => {
		try {
		
        if (data.password === data.passwordconfirmer) {
          const res=await resetPassword(id,data.password)
          console.log(res)
          if(res.msg==="PASSWORD_CHANGED"){
           history.push('/login')
          }
        }else{
            toast.error(
				"Les mots de passe ne correspondent pas.",
				{
					style: {
						fontFamily: 'Montserrat',
					},
				}
			)
        }
		 

		} catch (error) {
			console.log(error)
		}
	}
  return (
    
    <div>
	<CommonHeader />

    <div
        style={{
			height: '95vh',
            width: '100%',
            backgroundColor:'#f7f8f8',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        
        }}
    >
       

        <div
            style={{
				width: '100%',
                maxWidth: "600px",
                background:'white' ,
                borderRadius: "8px",
                padding: '2rem',
                boxShadow:"0 1px 3px rgba(0, 0, 0, 0.1)" ,
                fontFamily: 'Montserrat',
            }}
        >
			<h1 style={{
						 fontSize: "1.5rem",
						 fontWeight: 600,
						 textAlign: "center",
						 marginBottom: '2rem'
					}}>
                     Réinitialisation du mot de passe
                    </h1>
            <form
                onSubmit={handleSubmit(onSubmit)}
                style={{
					display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem'
                }}
            >
               <label
							style={{
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<span
									style={{
										marginBottom: 7,
										fontSize: "14px",
										color:'#606060',
										fontWeight:500,
										lineHeight:"17px"
									}}
								>
									Mot de passe 
								</span>
								
							</div>
							<input
								{...register('password', {
									required: 'Veuillez indiquer le mot de passe',
									minLength: {
										value: 8,
										message: 'Le mot de passe doit contenir au moins 8 caractères',
											},
								})}
								style={{
									padding: '1rem',
									fontFamily: 'Montserrat',
									color: '#fff',
									borderRadius: "8px",
									background: 'white',
									padding: '0.75rem',
									border: '1px solid #D9D9D9',
									width: '100%',
									':focus': {
										outline: 'none',
										border: '1px solid #5BBCEA',
									  }
								}}
								name='password'
								placeholder='Mot de passe'
								type='password'
							/>
							{errors.password && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.password.message}
                                    </div>
                               )}
			   </label>
               <label
							style={{
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<span
									style={{
										marginBottom: 7,
										fontSize: "14px",
										color:'#606060',
										fontWeight:500,
										lineHeight:"17px"
									}}
								>
									Confirmez le mot de passe
								</span>
								
							</div>
							<input
								{...register('passwordconfirmer', {
									required: 'Veuillez indiquer le mot de passe',
									minLength: {
										value: 8,
										message: 'Le mot de passe doit contenir au moins 8 caractères',
											},
								})}
								style={{
									padding: '1rem',
									fontFamily: 'Montserrat',
									color: '#fff',
									borderRadius: "8px",
									background: 'white',
									padding: '0.75rem',
									border: '1px solid #D9D9D9',
									width: '100%',
									':focus': {
										outline: 'none',
										border: '1px solid #5BBCEA',
									  },
								}}
								name='passwordconfirmer'
								placeholder='Mot de passe'
								type='password'
							/>
								{errors.passwordconfirmer && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.passwordconfirmer.message}
                                    </div>
                               )}
			   </label>
              

               

                <button
                    style={{
						color: 'white',
                        fontFamily: 'Montserrat',
                        background: '#5BBCEA',
                        border: 'none',
                        padding: '1rem',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        borderRadius: "8px",
                    }}
                    type='submit'
                >
                    Envoyer
                </button>

               
            </form>
        </div>
    </div>
    <Footer />
</div>
  )
}

export default ResetPassword