import React, { useEffect, useState } from 'react'
import {  accepterCommande, getCommandesPlateForme } from '../../api/commande'
import {
	searchCommandesByPriceForPlateforme,
	searchCommandesByDateForPlateforme,
	searchCommandesByIdForPlateforme
} from '../../api/transporteurs'

import CourseACourseHorizontal from '../../Components/SingleCommandeHorizontal/CourseACourseHorizontal'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import { GradientText } from '../PasserCommande/PasserCommande.styles'
import LayoutTransporter from '../../Components/LayoutTransporter/LayoutTransporter'
import MiseADispoHorizontal from '../../Components/SingleCommandeHorizontalForTransporteur/MiseADispoHorizontal'
import Modal from 'react-modal'
import Select from 'react-select'
import UltimatePaginationBasic from '../../Components/Pagination/Pagination'
import moment from 'moment'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import DetailCommandeModal from '../../Components/DetailCommande/DetailCommandeModal'

Modal.setAppElement('#root')
const SIZE = 10

function Plateforme() {
	const history = useHistory()
	const [page, setPage] = useState(1)
	const [totalPages,setTotalPages]=useState(1)

	const user = useSelector((state) => state.auth.user)
	const token = useSelector((state) => state.auth.token);
    
	const [modalIsOpenAccepte,setModalIsOpenAccepte]=useState(false)
   
	const [loading,setLoading]=useState(false)
    const [selectedCommande,setSelectedCommande]=useState(null)
	const [commandes, setCommandes] = useState([])
	const [modalIsOpenDetail,setModalIsOpenDetail]=useState(false)
	
	const [chargementEnCours, setChargementEnCours] = useState(false);
	const [isSearching, setIsSearching] = useState(false); 
	const [searchCriteria, setSearchCriteria] = useState('id')
	const [range, setRange] = useState([new Date(), new Date()])
	const [min, setMin] = useState(0)
	const [max, setMax] = useState(300)
	const [searchQueryId, setSearchQueryId] = useState('')
	const [selectedOption, setSelectedOption] = useState({
		value: 'id', label: "Recherche par l'identifiant " 
	 });


	 const handleChange = (selectedOption) => {
	   setSelectedOption(selectedOption);
	   setSearchCriteria(selectedOption.value)
	 };

	

	 const fetchCommandes = async (page) => {
		setChargementEnCours(true);
		try {
			let res;
			if (isSearching) {
				if (searchCriteria === 'id') {
					res = await searchCommandesByIdForPlateforme(user?._id, searchQueryId, page, SIZE, token);
				} else if (searchCriteria === 'prix') {
					res = await searchCommandesByPriceForPlateforme(user?._id, min, max, page, SIZE, token);
				} else if (searchCriteria === 'date') {
					let minDate = moment(range[0]).format();
					let maxDate = moment(range[1]).format();
					res = await searchCommandesByDateForPlateforme(user?._id, minDate, maxDate, page, SIZE, token);
				}
			} else {
				res = await getCommandesPlateForme(user._id, page, SIZE, token);
			}
			setCommandes(res.commandes);
			setTotalPages(res.totalPages);
		} catch (error) {
			console.log('error', error);
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			});
		} finally {
			setChargementEnCours(false);
		}
	};
	
	const resetData = async () => {
		setIsSearching(false); 
		setSearchQueryId(''); 
		setSearchCriteria("id")
		setSelectedOption({
			value: 'id', label: "Recherche par l'identifiant " 
		  })
		setPage(1);
	};
	
	const handleSearch = () => {
		setIsSearching(true); 
		setPage(1);
		if(isSearching) {
			fetchCommandes(1)
		}

	};
  
	useEffect(() => {
		fetchCommandes(page); 
	}, [page, isSearching]);
	

	return (
		<LayoutTransporter>
			<div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
				}}
			>
				<GradientText>PLATE-FORME</GradientText>
				<span
					style={{
						fontFamily: 'Montserrat',
						color: '#262626',
						marginTop: '0.5rem',
					}}
				>
					Ici la plate-forme vous propose différents transports que
					vous pouvez accepter
				</span>

				<div
					style={{
						width: '90%',
						fontFamily: 'Montserrat',
						minHeight: '60vh',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						marginTop: '1rem',
					}}
				>
					<div
						style={{
							width: '100%',
							fontFamily: 'Montserrat',
							paddingLeft: '1rem',
							paddingRight: '1rem',
							marginBottom: '2rem',
							marginTop: '1rem',
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<div
							style={{
								width: '350px',
								fontFamily: 'Montserrat',
								marginRight: '1rem',
							}}
						>
							<Select
								isSearchable={false}
								options={[
									{value: 'id', label: "Recherche par l'identifiant " },
									{ value: 'prix', label: 'Prix' },
									{ value: 'date', label: 'Date du Transports' },
								]}
								styles={{
									option: (provided, state) => ({
										...provided,
										fontFamily: 'Montserrat',
									}),
									control: (provided, state) => ({
										...provided,
										height: '3rem',
										minHeight: 'fit-content',
									}),
									valueContainer: (base) => ({
										...base,
										maxHeight: '3rem',
									}),
								}}
								value={selectedOption}
							    onChange={handleChange}
								menuPortalTarget={document.querySelector(
									'body'
								)}
							/>
						</div>
						{searchCriteria === 'id' ? (
						<input
							style={{
								padding: '0.4rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								height: '3rem',
								width: '20rem',
								fontFamily: 'Montserrat',
							}}
							value={searchQueryId}
							onChange={(e) => setSearchQueryId(e.target.value)}
							placeholder='Recherche'
						/>
					) : null}
						{searchCriteria === 'prix' ? (
							<>
								<input
									type='number'
									style={{
										padding: '0.4rem',
										borderRadius: 5,
										border: '1px solid #E4EAF0',
										height: '3rem',
										width: '20rem',
										fontFamily: 'Montserrat',
										marginRight: '1rem',
									}}
									value={min}
									onChange={(e) => {
										setMin(Number(e.target.value))
									}}
									placeholder='Prix min'
								/>
								<input
									type='number'
									style={{
										padding: '0.4rem',
										borderRadius: 5,
										border: '1px solid #E4EAF0',
										height: '3rem',
										width: '20rem',
										fontFamily: 'Montserrat',
									}}
									value={max}
									onChange={(e) => {
										setMax(Number(e.target.value))
									}}
									placeholder='Prix max'
								/>
							</>
						) : null}

						{searchCriteria === 'date' ? (
							<DateRangePicker
								onChange={setRange}
								value={range}
							/>
						) : null}

						<button
							onClick={handleSearch}
							style={{
								padding: '0.6rem 1rem',
								height: '3rem',
								background: '#C9D5F7',
								border: 'none',
								cursor: 'pointer',
								color: '#1C63C5',
								borderRadius: 7,
								//marginTop: 20,
								marginLeft: '1rem',
								fontFamily: 'Montserrat',
								fontWeight: 'bold',
							}}
						>
							Rechercher
						</button>

						<button
							onClick={resetData}
							style={{
								padding: '0.6rem 1rem',
								height: '3rem',
								background: '#C9D5F7',
								border: 'none',
								cursor: 'pointer',
								color: '#1C63C5',
								borderRadius: 7,
								marginRight: '1rem',
								marginLeft: '1rem',
								fontFamily: 'Montserrat',
								fontWeight: 'bold',
							}}
						>
							Effacer
						</button>
					</div>
					{chargementEnCours ?
					<div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'white',
                      marginLeft:"30rem",
                      marginTop:"10rem",
					  marginBottom:'3rem'
                    }}
                    >
                    <div className='customLoader'></div>	
                    </div>
                     :
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'wrap',
							width: '100%',
							height: '100%',
							alignItems: 'flex-start',
							marginBottom: '2rem',
						}}
					>
						{ commandes.length === 0 ? 
						<span
						style={{
							fontFamily: 'Montserrat',
							color: '#262626',
							marginTop: '0.5rem',
							textAlign: 'center',
							paddingLeft:"400px"
						}}
					>
						Il n'y a pas des commandes à afficher
					</span>
						:commandes.map((commande) => {
							if (commande.type_commande === 'course-a-course') {
								return (
									<CourseACourseHorizontal
										key={commande._id}
										commande={commande}
										hideRecu
										hideAttribuer
										hidefacture
										hideAccept={false}
										hidecancel
										hideStatus
										hideUpdate
										onClickDetails={() => {
											history.push(
												`/transports/${commande._id}`
											)
										}}
										onClickSignaler={() => {
											history.push(
												`/transporter/transports/${commande._id}/signaler`
											)
										}}
										onClickAccept={async () => {
											setSelectedCommande(commande._id)
											setModalIsOpenAccepte(true)	
										}}
									/>
								)
							} else {
								return (
									<MiseADispoHorizontal
										key={commande._id}
										commande={commande}
										hideRecu
										hideAttribuer
										hidecancel
										hideStatus
										hidefacture
										hideUpdate
										hideSignaler
										hideAccept={false}
										onClickDetails={() => {
											setSelectedCommande(commande._id)
										    setModalIsOpenDetail(true)
										}}
										onClickSignaler={() => {
											history.push(
												`/transporter/transports/${commande._id}/signaler`
											)
										}}
										onClickAccept={  () => {
											setSelectedCommande(commande._id)
											setModalIsOpenAccepte(true)
										}}
									/>
								)
							}
						})}
					</div>
                    }
					<div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<UltimatePaginationBasic
							currentPage={page}
							totalPages={totalPages}
							onChange={(newPage) => setPage(newPage)}
						/>
					</div>
				</div>
			</div>
			<DetailCommandeModal 
			  isOpen={modalIsOpenDetail} 
			  onClose={() => setModalIsOpenDetail(false)}
			  commandeId={selectedCommande}
			/>
			<Modal
				isOpen={modalIsOpenAccepte}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Accepter commande'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					>Accepter commande</div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>Êtes-vous sûr de vouloir  accepter cette commande ?</p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
					
						onClick={async () => {	
							setLoading(true)
							   try {
								await accepterCommande(
									selectedCommande,
									user?._id,
									token
								)
								toast.success("La commande a été acceptée avec succès", {
									style: {
										fontFamily: 'Montserrat',
									},
								})
							   } catch (error) {
								if (error.response && error.response.status === 403) {
						           toast.error(error.response.data.error, {
										style: {
											fontFamily: 'Montserrat',
										},
									});
								}else{
									toast.error("Une erreur s'est produite", {
										style: {
											fontFamily: 'Montserrat',
										},
									});
								}
							   }finally {
								setSelectedCommande(null)
								setLoading(false)
								setModalIsOpenAccepte(false)
								await fetchCommandes(page)

							}
							  
                              
                            }}

						disabled={loading}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
							display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
						}}
					>
					{loading ? (
									<div className='loader'></div>
								) : (
									'Confirmer'
								)}
					</button>
					<button
						onClick={ () => {
							setModalIsOpenAccepte(false)
												
						}}
						style={{
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	</Modal>
		</LayoutTransporter>
	)
}

export default Plateforme
