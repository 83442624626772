
import api from './base'
import qs from 'qs';


export const getClients = async (page, size,token) => {
	
	const res = await api.get(`/users?filter=client&fields=role&page=${page}&limit=${size}&nocache=${Date.now()}`,{
    headers: {
		  Authorization: `Bearer `+token
		},
	  })
	
	return res.data
}
export  const getNotValidClients=async(page, size,token)=>{
	const res = await api.get(`/users/not-validated/all?filter=client&fields=role&page=${page}&limit=${size}&nocache=${Date.now()}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
		
		return res.data
	
}
export  const getValidClients=async(page, size,token)=>{
	const res = await api.get(`/users/validated/all?filter=client&fields=role&page=${page}&limit=${size}&nocache=${Date.now()}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
		
		return res.data
	
}
export const getDeletelClient = async (page, size,token) => {
	
	const res = await api.get(`/users/delete/all?filter=client&fields=role&page=${page}&limit=${size}&nocache=${Date.now()}`,{
    headers: {
		  Authorization: `Bearer `+token
		},
	  })
	
	return res.data
}


export const restoreDeletedClient = async (id,token) => {
	
	const res = await api.get(`/users/restore/${id}`,{
    headers: {
		  Authorization: `Bearer `+token
		},
	  })
	
	return res.data
}
export const restoreDeletedTransporteur = async (id,token) => {
	
	const res = await api.get(`/users/transporteur/restore/${id}`,{
    headers: {
		  Authorization: `Bearer `+token
		},
	  })
	
	return res.data
}
export const getClientsForFacturation  = async (query,page,size,token) => {
	const res = await api.get(`/users/clientforfacturation/${query}?page=${page}&limit=${size}&nocache=${Date.now()}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getClientsByDateForFacturation  = async (query,token) => {
	const res = await api.get(`/users/clientforfacturation/${query}/date`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getSingleClient = async (_id,token) => {
	const res = await api.get(`/users/${_id}?nocache=${Date.now()}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}

export const getSingleTransporter = async (_id,token) => {
	const res = await api.get(`/users/${_id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  }
	)
	return res.data
}

export const getSingleChauffeur = async (_id,token) => {
	const res = await api.get(`/users/${_id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}


export const toggleSuspendreClient = async (id,data,token) => {
	const res = await api.patch(`/users/suspend/${id}`, data,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}

export const updateClient = async (id, values,token) => {
	const params = new URLSearchParams()

	Object.entries(values).map((item) => params.append(item[0], item[1]))

	const config = {
		headers: {
			Authorization: `Bearer `+token,
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	}

	const res = await api.patch(`/users/${id}`, params, config)

	return res.data
}

export const updateTransporter = async (id, values,token) => {
	

	const config = {
		headers: {
			Authorization: `Bearer `+token,
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	}

	const res = await api.patch(`/users/${id}`, qs.stringify(values), config);


	return res.data
}

export const deleteClient = async (id,token) => {
	const res = await api.delete(`/users/${id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const softDeleteClient = async (id,token) => {
	const res = await api.get(`/users/softdelete/${id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}

export const getComptabiliteClients = async (page, size,token) => {
	const res = await api.get(`/comptabilite/clients?size=${size}&page=${page}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getComptabiliteClientsByMonth = async (page, size,token,month,year) => {
	const res = await api.get(`/comptabilite/clients/month?size=${size}&page=${page}&month=${month}&year=${year}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getComptabiliteClientsByMonthAndName = async (page, size,token,month,year,query) => {
	query=query.trim()
	const res = await api.get(`/comptabilite/clients/month/${query}/all?size=${size}&page=${page}&month=${month}&year=${year}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getComptabiliteClientByName= async (name,page,size,token) => {
	name=name.trim()
	const res = await api.get(`/comptabilite/client/${name}?page=${page}&size=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}





export const updateChauffeurAccount = async (data,token) => {
	const res = await api.patch('/profile', data,{
		headers: {
			  Authorization: `Bearer `+token,
			},
		  })
	return res.data
}
export const updateClientAccount = async (clientId,data,files,token) => {

	const formData = new FormData();
 
	Object.keys(data).forEach(key => {
		if (typeof data[key] === 'object') {
		  formData.append(key, JSON.stringify(data[key]));
		} else {
		  formData.append(key, data[key]);
		}
	  });
  

   
	files.forEach(fileObj => {
		const [fileField, file] = Object.entries(fileObj)[0];
		formData.append(fileField, file);
	  });
	const res = await api.patch(`/profile/${clientId}`, formData,{
		headers: {
			'Content-Type': 'multipart/form-data',
			'Authorization': `Bearer ${token}`
			},
		  })
	return res.data
}

export const searchCommandesByPrixMinMaxForClient = async (min,max,client,type,token,page,size) => {
	const res = await api.get(`/commandes/client/price/${client}/${min}/${max}/all?filter=${type}&page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}

export const searchCommandesByDateForClient = async (min, max, client,type,token,page,size) => {
	const res = await api.get(`/commandes/client/date/${client}/${min}/${max}/all?filter=${type}&page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}
export const searchCommandesByIdForClient=async(client,query,type,token,page,size)=>{
	const res = await api.get(`/commandes/byclient/${client}/${query}/all?filter=${type}&page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}

export const searchCommandesAnnuleesByIdForClient=async(client,query,token,page,size)=>{
	const res = await api.get(`/commandes/byclient/annulees/${client}/${query}?page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const searchCommandesAnnuleesByPrixMinMaxForClient = async (min,max,client,token,page,size) => {
	const res = await api.get(`/commandes/byclient/annulees/price/${client}/${min}/${max}?page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}
export const searchCommandesAnnulesByDateForClient = async (min, max, client,token,page,size) => {
	const res = await api.get(`/commandes/byclient/annulees/date/${client}/${min}/${max}/all?page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}


export const affecterClientACommercial = async (
	clients,
	commercialID,
	token
) => {
	const res = await api.post('/users/affecter-client-commercial', {
		clients,
		commercialID,
	},{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}
export const removeClientFromCommercial=async (
	clients,
	token
) => {
	const res = await api.post('/users/remove-client-commercial', {
		clients
	},{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}
export const getClientsWithFailedCharge=async(query,token)=>{
	const  res=await api.get(`/users/admin/clientwithfailedcharge/${query}/all`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getClientsForPrelevement=async(query,token)=>{
	const  res=await api.get(`/users/admin/prelevement/${query}/all`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const comparerReductions = async (
	clients,
	commercialID,
	token
) => {
	const res = await api.post('/users/comparer-reductions', {
		clients,
		commercialID,
	},{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}
export const getClientStats=async(clientId,token)=>{
	const res = await api.get(`/commandes/client/stats/${clientId}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data

}