import React ,{ useEffect, useState }from 'react'
import Modal from 'react-modal'
import { useHistory ,useParams} from 'react-router'
import { useSelector } from 'react-redux'
import { getChauffeursByTransporter } from '../../../api/chauffeurs'
import { GradientText } from '../../PasserCommande/PasserCommande.styles'
import LayoutAdmin from '../../../Components/LayoutAdmin/LayoutAdmin'
import DataTable from 'react-data-table-component'
import toast from 'react-hot-toast'
import moment from 'moment'

Modal.setAppElement('#root')


function TransporteurListChauffeur() {
    const { id } = useParams()
	const history = useHistory()
	const [chauffeurs, setChauffeurs] = useState([])
	const [totalRows, setTotalRows] = useState(0)
	const [perPage, setPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
	const [chargementEnCours,setChargementEnCours]=useState(false)
	const token = useSelector((state) => state.auth.token);

	const fetchChauffeurs=async(page,perPage)=>{
		setChargementEnCours(true)
		try {
		const res = await getChauffeursByTransporter(id,page,perPage,token,null)
		setChauffeurs(res.docs)
		setTotalRows(res.totalDocs)
		} catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			});
		}finally{
			setChargementEnCours(false)

		}
	}

	const handlePageChange = (page) => {
		setCurrentPage(page)
		fetchChauffeurs(page,perPage)
	}
	const handlePerRowsChange = async (newPerPage, page) => {
		fetchChauffeurs(page,newPerPage)
		setPerPage(newPerPage)
		
	}
	useEffect(() => {
		fetchChauffeurs(currentPage,perPage)
	}, [id])
	const columns = [
        {
            name: "ID",
            selector: (row) => row.__id,
        },
        {
            name: "Nom  du  chauffeur",
			selector: (row) => `${row.first_name} ${row.last_name}`,
			sortable: false,        
		},
		{
			name: 'Email',
			selector: (row) => `${row.email}`,
			sortable: false,
			width:'250px'
		},
		{
			name: 'Statut',
			selector: (row) => (row.suspendre ? 'Suspendu' : 'Activé'),
			sortable: false,
		},
		{
			name: "Date de suspension",
			selector: (row) => (row?.suspendre ? moment(row?.dateSuspension).format('DD/MM/YYYY') : '__'),
			sortable: false,
		},
		{
			name: 'Actions',
			button: true,
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						onClick={()=>{
							history.push(`/admin/transports/chauffeur/single/${row._id}`)
						}}
					>
	                 <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      >
                      <path
                      fill="#51C7F2"
                       d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z"
                       />
                       <path
                       fill="#51C7F2"
                        d="M11 7h2v7h-2zm0 8h2v2h-2z"
                       />
                      </svg>

					</div>
				</div>
					
					
					
					
				</div>	
				</div>
			),
		},
    ];

  return (
    
    <LayoutAdmin>
			<div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
				<GradientText>Liste CHAUFFEURS</GradientText>
				<div
					style={{
						width: '100%',
						padding: '0 1rem',
						marginTop:'2rem'
					}}
				>
					<DataTable
						columns={columns}
						data={chauffeurs}
						progressPending={chargementEnCours}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						noDataComponent="Il n'y a pas des chauffeurs à afficher"
						progressComponent={
							<div
							style={{
							  display: 'flex',
							  alignItems: 'center',
							  justifyContent: 'center',
							  backgroundColor: 'white',
							  marginTop:"10rem"
							}}
							>
							<div className='customLoader'></div>	
							</div>
						}
						paginationComponentOptions={{
							rowsPerPageText: 'Rangs par page',
							rangeSeparatorText: 'sur',
						}}
					/>
				</div>
			</div>
	</LayoutAdmin>
  )
}

export default TransporteurListChauffeur