import React, { useState,useEffect } from 'react'
import Modal from 'react-modal';
import { getCommande } from '../../api/commande'
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { FaCheck } from 'react-icons/fa';
import { BASE_URL } from '../../api/base'

// Style pour le modal
const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '0',
        borderRadius: '10px',
        border: 'none',
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
        fontFamily: 'Montserrat, sans-serif',
        maxHeight: '80vh',
        overflow: 'hidden',
        width: "70%",
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
};

// Styles pour les éléments du modal
const headerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#5BBCEA',
    padding: '20px',
    position: 'relative',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
};

const closeButtonStyle = {
    position: 'absolute',
    right: '15px',
    backgroundColor: 'transparent',
    padding: '0',
    color: '#FF5A5F',
    border: 'none',
    borderRadius: '50%',
    width: '35px',
    height: '35px',
    cursor: 'pointer',
    fontSize: '22px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    transition: 'background-color 0.3s, color 0.3s',
};

const contentStyle = {
    padding: '20px',
    background: '#F7F8F8',
    overflowY: 'auto',
    maxHeight: 'calc(80vh - 60px)',
    fontFamily: 'Montserrat',

};
const details={
    padding: "15px",
    marginTop:'10px',
    marginBottom:"10px",
    borderTop: '1px solid #D9D9D9A8'
}
const detailsTitle={
    color:"#333333",
    fontSize:"14px",
    fontWeight:400,
     lineHeight: '19.5px'
}
const detailsSpan={
    color:"#606060",
    fontSize:"14px",
    fontWeight:400,
    lineHeight: '19.5px'
}

const title={
    color:'#25A6E4',
    fontSize:'16px',
    fontWeight: 500,
    lineHeight: '19.5px'
}
const icon= {
    color: 'green',
    marginRight: '10px',
  }
const listItem={
    display: 'flex',
    alignItems: 'center',
    marginTop:'8px',
    
}
const DetailCommandeModal=({ isOpen, onClose, commandeId })=> {
    const [chargementEnCours,setChargementEnCours]=useState(false)
    const token = useSelector((state) => state.auth.token);
    const user = useSelector((state) => state.auth.user);
    const [commande,setCommande]=useState(null)

    const fetchCommande=async(commandeId)=>{
        setChargementEnCours(true)
        try {
           const res=await  getCommande(commandeId,token)
           setCommande(res)
        } catch (error) {
            toast.error("Une erreur s'est produite", {
                style: {
                    fontFamily: 'Montserrat',
                },
            });  
        }finally{
         setChargementEnCours(false)
        }
    }
    const renderStatutMission = (statut) => {
      switch (statut) {
          case "init":
              return "Non commencée";
          case "en-cours":
              return "En cours";
          case "terminer":
              return "Terminée";
          case "annuler":
              return "Annulée";
          default:
              return "Inconnu";
      }
  };
    const renderMissionDetails = (mission, index) => (
        <div key={index}>
        <span style={title}>Détails de mission N° {index+1}</span>
        <p style={detailsTitle}>Date de début : {' '}
        <span style={detailsSpan}>{mission?.date_debut}{" "}{mission?.heure_debut}</span>
        </p>
        <p style={detailsTitle}>Date de fin : {' '}
        <span style={detailsSpan}>{mission?.date_fin}{" "}{mission?.heure_fin}</span>
        </p>
        <p style={detailsTitle}>Adresse de début : {' '}
        <span style={detailsSpan}>{mission?.adresse_debut?.label}</span>
        </p>
        <p style={detailsTitle}>Adresse de fin : {' '}
        <span style={detailsSpan}>{mission?.adresse_fin?.label}</span>
        </p>
        <p style={detailsTitle}>
         Statut de la mission: {' '}
         <span style={detailsSpan}>{renderStatutMission(mission?.statut)} </span>
      </p>
        <p style={detailsTitle}>Nombre de kilometrage : {' '}
        <span style={detailsSpan}>{mission?.kilometrage}KM</span>
        </p>
        <p style={detailsTitle}>Nature du chargement : {' '}
        <span style={detailsSpan}>{mission?.nature_chargement}</span>
        </p>
        {mission?.nature_chargement === "vrac" ?
         <p style={detailsTitle}>Nombre de dimension : {' '}
         <span style={detailsSpan}>{mission?.nombre_dimension}</span>
         </p>
        :
        <p style={detailsTitle}>Nombre de palette : {' '}
        <span style={detailsSpan}>{mission?.nombre_palettes}</span>
        </p>
        }
        <p style={detailsTitle}>Lieu de livraison : {' '}
        <span style={detailsSpan}>{mission?.livraison}</span>
        </p>
       {mission?.livraison==="Domicile/Batiment/Maison" &&
       <>
       <p style={detailsTitle}>Nombre d'étage : {' '}
        <span style={detailsSpan}>{mission?.etage}</span>
       </p>
       <p style={detailsTitle}>Assenceur : {' '}
        <span style={detailsSpan}>{mission?.assenceur ? "Oui" :"Non"}</span>
       </p>
       <p style={detailsTitle}>Escalier : {' '}
        <span style={detailsSpan}>{mission?.escalier ? "Oui" :"Non"}</span>
       </p>
       
       </>
       }
       <p style={detailsTitle}>Type de transport : {' '}
        <span style={detailsSpan}>{mission?.type_transport}{' '}{mission?.type_transport === "Poids lourds" ? mission?.vehicule_poids_lourds : mission?.type_transport_details}</span>
       </p>
       {mission?.type_transport === "Poids lourds" ?
       <p style={detailsTitle}>Options poids lourd : {' '}
       <span style={detailsSpan}>{mission?.options_poids_lourds.join(',')}</span>
       </p>
       :
       <p style={detailsTitle}>Options véhicules léger : {' '}
       <span style={detailsSpan}>{mission?.options_vehicule_leger.join(',')}</span>
       </p>
       }
       <p style={detailsTitle}>Manutention : {' '}
        <span style={detailsSpan}>{mission?.manutention_chauffeur}</span>
       </p>
       <p style={detailsTitle}>Nombre de rippeurs: {' '}
        <span style={detailsSpan}>{mission?.nombre_rippeur ? mission?.nombre_rippeur :0 }</span>
       </p>
       <p style={detailsTitle}>Matières dangereuses : {' '}
        <span style={detailsSpan}>{mission?.danger}</span>
       </p>
       <p style={detailsTitle}>Assurance : 
            <span style={detailsSpan}>{mission?.assurance ? "Oui" : "Non"}</span>
       </p>
       {mission?.assurance === "Oui" && (
            <p style={detailsTitle}>Montant de l'assurance : 
            <span style={detailsSpan}>{mission?.assurance_amount} €</span>
           </p>
        )}
       <p style={detailsTitle}>Notes utiles: {' '}
        <span style={detailsSpan}>{mission?.notes}</span>
       </p>
       <p style={detailsTitle}>Documents associés: {' '}
        {mission?.files?.length > 0 ?(
            mission?.files?.map((file, index) => (
              <>
                <span key={index} style={detailsTitle}>
                <a href={`${BASE_URL}/${file?.path}`} target="_blank" rel="noopener noreferrer">
                  {file?.name}
                </a>
              </span>
              <span style={{marginLeft:'.5rem'}}/>
              </>
        
            ))
        )
        :
        (
            <span style={detailsSpan}>Aucun document associé</span>
        )
        }
      </p>

       </div>
      );
    const calculerPrix = (commande, user) => {
      const sommeForfaits = commande?.missions?.flatMap(mission => mission.forfaits ?? [])
      .reduce((total, forfait) => total + (forfait?.prix ?? 0), 0) ?? 0;
  
    const prixTotal = (commande?.prix ?? 0) + sommeForfaits;
  
    const prixFinal = user?.role === 'client'
      ? prixTotal.toFixed(2) // Prix sans marge pour les clients
      : (prixTotal - (prixTotal * (commande?.margin ?? 0)) / 100).toFixed(2); // Prix avec marge pour les autres rôles
  
    return prixFinal
    };
   
    useEffect(()=>{
        if(commandeId && isOpen){
            fetchCommande(commandeId) 
        }
    },[commandeId,isOpen])
    
  return (
   <Modal
   isOpen={isOpen} 
   onRequestClose={onClose} 
   ariaHideApp={false}
   style={modalStyles}>
  <div style={headerStyle}>
    <h3 style={{ color: 'white', margin: 0, flex: 1, textAlign: 'center' }}>{`Détail Commande N°${commande ? commande?.__id :''}`}</h3>
    <button 
    onClick={onClose}  
    style={closeButtonStyle}
    >
    X
    </button>
  </div>
  {chargementEnCours ?
  <div
  style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    marginTop:"2rem",
    marginBottom:"2rem"
  }}
  >
  <div className='customLoader'></div>	
  </div>
   :
  <div style={contentStyle}>
  <div
	style={{
		padding: '0rem 1em',
		color: 'white',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		borderRadius: '0.5rem',
		fontSize: 15,
		}}
		>
		<span 
        style={title}>
            Détails transport
        </span>
        <div
			style={{
				padding: '1rem 1em',
			    background: '#5BBCEA',
				color: 'white',
				fontFamily: 'Montserrat',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
				borderRadius: '0.5rem',
				fontSize: 15,
				}}
			>
            <span>Prix: </span>
		    <span style={{ width: 20 }} />
	        <span style={{ fontWeight: 'bold' }}>
          {calculerPrix(commande, user)}€ ht
	        </span>
							
		</div>
     </div>
      <div style={details}>
      <p style={detailsTitle}> Type de transport : {' '}
      <span style={detailsSpan}>
        {commande?.type_commande==="mise-a-disposition" ? "Mise à disposition" :"Course à course"}
        </span>
      </p>
      <p  style={detailsTitle}> Type de temporisation: {' '}
      <span style={detailsSpan}>
        {commande?.temporisation==="immediat" ? "Immédiat" :"Planifié"}
      </span>
      </p>
      <p style={detailsTitle}>
     Date du transport: {' '}
      <span style={detailsSpan}>
        {moment(commande?.date_transport).format('DD/MM/YYYY HH:mm')}
        </span>
      </p>
      <p style={detailsTitle}>
      Statut de la commande: {' '}
      <span style={detailsSpan}>{renderStatutMission(commande?.statut)}</span>
      </p>
      <p style={detailsTitle}>
      Statut de paiement: {' '}
      <span style={detailsSpan}>
     {commande?.paymentStatus==='payé' ? 'Payé' : 'Non Payé'}
     </span>
      </p>
      </div>
      {commande?.type_commande==="mise-a-disposition" && commande?.missions?.length > 0 &&
      <div style={details}>
       {commande?.missions?.map(renderMissionDetails)}
       </div> 
       }
      
      <div style={details}>
        <span style={title}>Informations sur le signataire</span>
      <p style={detailsTitle}>
      Nom & Prénom: {' '}
      <span 
      style={detailsSpan}
      >
        {commande?.nom_signataire }{" "}{commande?.prénom_signataire}
        </span>
      </p>
    
      <p style={detailsTitle}>
      Numéro de Téléphone 1 : {' '}
      <span 
      style={detailsSpan}
      >
      {commande?.téléphone1_signataire}
      </span>
      </p>
      <p style={detailsTitle}>
        Numéro de Téléphone 2 : {' '}
      <span 
      style={detailsSpan}
      >
      {commande?.téléphone2_signataire}
     
        
        </span>
      </p>
      <p style={detailsTitle}>Adresse email : {' '}
      <span style={detailsSpan}>{commande?.email_signataire} </span>
      </p>
      </div>
      <div style={details}>
      <span style={title}>Matériels supplémentaire</span>
      <p style={detailsTitle}>
      Matériels de manutention: {' '}
      {commande?.manutention.map((item, index) => (
        <div style={listItem} key={index}>
            <FaCheck style={icon}/> 
            <span  style={detailsSpan}>{item}</span>
        </div>
      ))}
      </p>
      <p style={detailsTitle}>
      Epi: {' '}
      {commande?.epi.map((item, index) => (
        <div style={listItem} key={index}>
            <FaCheck style={icon}/> 
            <span  style={detailsSpan}>{item}</span>
        </div>
      ))}
      </p>
      {commande?.transpalette_électrique &&
      <p style={detailsTitle}>
      Transpalette électrique: {' '}
      <FaCheck style={icon}/> 
      </p>
     }
    
     
      </div>
      <div style={details}>
      <span style={title}>Informations supplémentaires</span>
      
      <p style={detailsTitle}>Référence interne client: {' '}
      <span style={detailsSpan}>
        {commande?.ref_client}
      </span>
      </p>
      <p style={detailsTitle}>Documents associés: {' '}
        {commande?.files?.length > 0 ?(
            commande?.files?.map((file, index) => (
                <>
                <span key={index} style={detailsTitle}>
                <a href={`${BASE_URL}/${file?.path}`} target="_blank" rel="noopener noreferrer">
                  {file?.name}
                </a>
              </span>
              <span style={{marginLeft:'.5rem'}}/>
              </>
            ))
        )
        :
        (
            <span style={detailsSpan}>Aucun document associé</span>
        )
        }
      </p>
     
      </div>
  </div>
  }
   </Modal>
  )
}

export default DetailCommandeModal