import {
	FormContentSection,
	FormSectionCard,
	FormSectionTitle,
} from '../../PasserCommande/PasserCommande.styles'
import React, { useEffect, useState } from 'react'
import {
	getSingleTransporter,
	toggleSuspendreTransporter,
	softDeleteTransporter,
	sendTransporterDocument
} from '../../../api/transporteurs'
import Switch from 'react-switch'
import { useHistory, useParams } from 'react-router'
import { CustomInput } from '../../RegisterTransporter/style'
import File from '../../../assets/img/file@2x.png'
import LayoutAdmin from '../../../Components/LayoutAdmin/LayoutAdmin'
import { BASE_URL } from '../../../api/base'
import toast from 'react-hot-toast'
import { updateTransporter } from '../../../api/clients'
import { useForm,Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import Modal from 'react-modal'
Modal.setAppElement('#root')
function SingleTransporter() {

	const {  handleSubmit,control, reset  } = useForm()
	const { id } = useParams()

	const history = useHistory()
	const token = useSelector((state) => state.auth.token);
	const [modalIsOpenDelete,setModalIsOpenDelete]=useState(false)
	const [modalIsOpen,setModalIsOpen]=useState(false)
	const [modalIsOpenReject,setModalIsOpenReject]=useState(false)
	const [modalIsOpenUpdate,setModalIsOpenUpdate]=useState(false)
	const [formValues, setFormValues] = useState(null); 
	const [transporter, setTransporter] = useState(null)
	const [suspendre, setSuspendre] = useState(false)
	const [loading, setLoading] = useState(false)
	const user = useSelector((state) => state.auth.user)
	const [chargementEnCours, setChargementEnCours] = useState(false);
	const [documentToReject, setDocumentToReject] = useState(null);
	const isArchiveMode = window.location.pathname.includes('archive');  
	const isNewMode = window.location.pathname.includes('new');  


	useEffect(() => {

		async function getTransporter(id) {
			setChargementEnCours(true)
			try {
			const data = await getSingleTransporter(id,token)
			setTransporter(data)
			setSuspendre(data.suspendre === true)
			reset(data)
			} catch (error) {
				toast.error("Une erreur s'est produite", {
					style: {
						fontFamily: 'Montserrat',
					},
			});
			}finally{
				setChargementEnCours(false)
			}
			

		}

		if (id) {
			getTransporter(id)
		}
	}, [id])

	const onSubmit = async (values) => {
		try {
		setFormValues(values)
		setModalIsOpenUpdate(true)
        		
		} catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			})
		}
		
	
	
	
	}
    const onError = (errors) => {
		console.log('Erreur de soumission', errors);
		toast.error("Une erreur s'est produite", {
			style: {
				fontFamily: 'Montserrat',
			},
		})};
	

	return (
		<LayoutAdmin>
			<div
				style={{
					width: '100%',
					fontFamily: 'Montserrat',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					paddingBottom: '1rem',
				}}
			>
			    {chargementEnCours ?
				<div
			style={{
			  display: 'flex',
			  alignItems: 'center',
			  justifyContent: 'center',
			  backgroundColor: 'white',
			  marginTop:"10rem"
			}}
			>
			<div className='customLoader'></div>	
			    </div>
				:	
				<form
					style={{ width: '75%' }}
					onSubmit={handleSubmit(onSubmit, onError)}

				>
					<FormSectionCard>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<div>
								<p
									style={{
										color: 'black',
										fontWeight: 'bold',
									}}
								>
									Fiche Transporteur
								</p>
								
							</div>
                           {!isNewMode &&
							<div
	                         style={{
		                      display: 'flex',
	                        }}
                            >
	                        {!isArchiveMode && (
		                    <>
			                <button
				            type="button"
				            onClick={() => setModalIsOpen(true)}
				            style={{
					        border: 'none',
					        background: '#ECC9F7',
					        color: '#AB1CC5',
					        fontSize: 17,
					        fontFamily: 'Montserrat',
					        fontWeight: 'bold',
					        padding: '0.6rem 1rem',
					        borderRadius: 5,
					        cursor: 'pointer',
				            }}
			                >
			             	{suspendre ? 'Activer' : 'Suspendre'}
		                	</button>

			{user.role === 'super admin' && (
				<button
					type="button"
					onClick={() => setModalIsOpenDelete(true)}
					style={{
						border: 'none',
						background: '#F7CDC9',
						color: '#C5431C',
						fontSize: 17,
						fontFamily: 'Montserrat',
						fontWeight: 'bold',
						padding: '0.6rem 1rem',
						borderRadius: 5,
						cursor: 'pointer',
						marginLeft: '1rem',
					}}
				>
					Supprimer
				</button>
			)}

			<button
				type="submit"
				style={{
					border: 'none',
					background: '#168EEA',
					color: '#FFF',
					fontSize: 17,
					fontFamily: 'Montserrat',
					fontWeight: 'bold',
					padding: '0.6rem 1rem',
					borderRadius: 5,
					cursor: 'pointer',
					marginLeft: '2rem',
				}}
			>
				Enregistrer
			</button>
		</>
	)}

	<button
		type="button"
		onClick={() => {
			const basePath = isArchiveMode
			  ? `/admin/transports-archived/${transporter._id}/chauffeur`
			  : `/admin/transports/${transporter._id}/chauffeur`;
			history.push(basePath);
		  }}
		style={{
			border: 'none',
			background: '#50F5A9',
			color: '#FFF',
			fontSize: 17,
			fontFamily: 'Montserrat',
			fontWeight: 'bold',
			padding: '0.6rem 1rem',
			borderRadius: 5,
			cursor: 'pointer',
			marginLeft: !isArchiveMode ? '1rem' : '0',
		}}
	>
		Liste Chauffeur
	 </button>
                            </div>
                           }

						</div>
					</FormSectionCard>

					<FormSectionCard>
						<div 
						style={{
							display: 'flex',
							flexDirection: 'row',
						}}>
						<div
							style={{
								width: '50%',
								display: 'flex',
								flexDirection: 'column',
								paddingBottom: '2rem',
								marginRight:'1rem'
							}}
						>
							<span
								style={{
									color: '#262626',
									paddingBottom: '1rem',
								}}
							>
								Nom société
							</span>
							<CustomInput
							    disabled
								type='text'
								placeholder='Wayne'
								value={transporter?.societe ||''}
								style={{
									backgroundColor: '#f0f0f0', 
									color: '#a9a9a9',           
									cursor: 'not-allowed'       
							    }}
							/>
						</div>

						<div
							style={{
								width: '50%',
								display: 'flex',
								flexDirection: 'column',
								paddingBottom: '2rem',
							}}
						>
							<span
								style={{
									color: '#262626',
									paddingBottom: '1rem',
								}}
							>
								Nom
							</span>
							<CustomInput
								type='text'
								disabled
								placeholder='Doe'
								value={transporter?.last_name ||''}
								style={{
									backgroundColor: '#f0f0f0', 
									color: '#a9a9a9',           
									cursor: 'not-allowed'       
							}}

							/>
						</div>
						</div>
						<div 
						style={{
							display: 'flex',
							flexDirection: 'row',
						}}>
						<div
							style={{
								width: '50%',
								display: 'flex',
								flexDirection: 'column',
								paddingBottom: '2rem',
								marginRight:'1rem'
							}}
						>
							<span
								style={{
									color: '#262626',
									paddingBottom: '1rem',
								}}
							>
								Prénom
							</span>
							<CustomInput
							    disabled
								type='text'
								placeholder='John'
								value={transporter?.first_name ||''}
								style={{
									backgroundColor: '#f0f0f0', 
									color: '#a9a9a9',           
									cursor: 'not-allowed'       
							}}

								
							/>
						</div>

						<div
							style={{
								width: '50%',
								display: 'flex',
								flexDirection: 'column',
								paddingBottom: '2rem',
							}}
						>
							<span
								style={{
									color: '#262626',
									paddingBottom: '1rem',
								}}
							>
								Qualité
							</span>
							<CustomInput
							    disabled
								type='text'
								placeholder='Directeur d’exploitation'
								value={transporter?.quality ||''}
								style={{
									backgroundColor: '#f0f0f0', 
									color: '#a9a9a9',           
									cursor: 'not-allowed'       
							    }}

							/>
						</div>
                        </div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								paddingBottom: '2rem',
							}}
						>
							<span
								style={{
									color: '#262626',
									paddingBottom: '1rem',
								}}
							>
								Adresse
							</span>
							<CustomInput
								type='text'
								disabled
								placeholder='54 rue avenue de la grande armée, 75017, Paris, France'
								value={transporter?.address ||''}
								style={{
									backgroundColor: '#f0f0f0', 
									color: '#a9a9a9',           
									cursor: 'not-allowed'       
							    }}

							/>
						</div>

						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								paddingBottom: '2rem',
							}}
						>
							<span
								style={{
									color: '#262626',
									paddingBottom: '1rem',
								}}
							>
								Ville
							</span>
							<CustomInput
								type='text'
								disabled
								placeholder='54 rue avenue de la grande armée, 75017, Paris, France'
								value={transporter?.city ||''}
                               style={{
											backgroundColor: '#f0f0f0', 
											color: '#a9a9a9',           
											cursor: 'not-allowed'       
									}}
							/>
						</div>

						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								paddingBottom: '2rem',
							}}
						>
							<span
								style={{
									color: '#262626',
									paddingBottom: '1rem',
								}}
							>
								Code Postal
							</span>
							<CustomInput
								type='text'
								disabled
								placeholder='54 rue avenue de la grande armée, 75017, Paris, France'
								value={transporter?.postalCode ||''}
								style={{
									backgroundColor: '#f0f0f0', 
									color: '#a9a9a9',           
									cursor: 'not-allowed'       
							}}

							/>
						</div>
                        <div 
						style={{
							display: 'flex',
							flexDirection: 'row',
						}}>
						<div
							style={{
								width: '50%',
								display: 'flex',
								flexDirection: 'column',
								paddingBottom: '2rem',
								marginRight:'1rem'
							}}
						>
							<span
								style={{
									color: '#262626',
									paddingBottom: '1rem',
								}}
							>
								Email
							</span>
							<CustomInput
								type='text'
								disabled
								placeholder='test@gmail.com'
								value={transporter?.email ||''}
								style={{
									backgroundColor: '#f0f0f0', 
									color: '#a9a9a9',           
									cursor: 'not-allowed'       
							}}
							/>
						</div>

						<div
							style={{
								width: '50%',
								display: 'flex',
								flexDirection: 'column',
								paddingBottom: '2rem',
							}}
						>
							<span
								style={{
									color: '#262626',
									paddingBottom: '1rem',
								}}
							>
								Téléphone
							</span>
							<CustomInput
								type='text'
								disabled
								placeholder='(0) 01 45 66 32 88'
								value={transporter?.phone ||''}
								style={{
									backgroundColor: '#f0f0f0', 
									color: '#a9a9a9',           
									cursor: 'not-allowed'       
							}}
							/>
						</div>
						</div>
						
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
							     TVA
								</span>
								<Controller
									control={control}
									name='tva'
									render={({
										field: { value, onChange },
									}) => (
										<Switch
											onChange={onChange}
											checked={value}
											disabled={isArchiveMode ||isNewMode}
											offColor='#F3F6F9'
											onColor='#F3F6F9'
											checkedIcon={false}
											uncheckedIcon={false}
											offHandleColor='#BFBFBF'
											onHandleColor='#168EEA'
											height={40}
											width={80}
											boxShadow='0px 5px 7px 0px rgba(0,0,0,0.23)'
										/>
									)}
								/>
							</div>
							<div style={{ width: 50 }} />
                            
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								marginBottom: '1rem',
								width: '50%',
								fontFamily: 'Montserrat',
								marginLeft: 10,
								marginTop: 10,
							}}
						>
							{transporter?.logo && (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
									}}
								>
									<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										Logo(PNG ou JPG)
									</span>
								</div>
									<div>
										<img
											style={{
												width: 100,
												height: 100,
												borderRadius: 5,
												objectFit: 'cover',
											}}
											src={`${BASE_URL}/${transporter.logo.path}`}
										/>
									</div>
								
							</div>
							)}
						</div>
                       
					</FormSectionCard>

					<div style={{ height: '2rem' }} />

					<FormContentSection>
					<FormSectionTitle>Véhicules</FormSectionTitle>
					<FormSectionCard>
			        <table
		            cellPadding={0}
		            cellSpacing={0}
		            style={{
			        width: '100%',
			        textAlign: 'left',
			        borderRadius: 20,
			        overflow: 'hidden',
			        border: 'none',
		           }}
	            >
		        <thead>
			    <tr
				style={{
					paddingBottom: 10,
			  	}}
			    >
				<th
					style={{
						fontWeight: 'normal',
						color: '#262626',
						padding: '1rem',
					}}
				>
					Détails
				</th>
				<th
					style={{
						fontWeight: 'normal',
						color: '#262626',
						padding: '1rem',
					}}
				>
					Options
				</th>
				<th
					style={{
						fontWeight: 'normal',
						color: '#262626',
						padding: '1rem',
					}}
				>
					Date de mise en circulation
				</th>
				<th
					style={{
						fontWeight: 'normal',
						color: '#262626',
						padding: '1rem',
					}}
				>
					Immatriculation
				</th>
				<th
					style={{
						fontWeight: 'normal',
						color: '#262626',
						padding: '1rem',
					}}
				>
					Location
				</th>
				<th
					style={{
						fontWeight: 'normal',
						color: '#262626',
						padding: '1rem',
					}}
				>
					Carte grise
				</th>
				
			</tr>
		  </thead>

		   <tbody>
			{transporter?.vehicules?.map((v, index) => (
				<tr
					key={String(index)}
					style={{
						background: 'white',
					}}
				>
					<td
						style={{
							padding: '1rem',
						}}
					>
						{v?.type == 'Poids lourds' &&
							Array.isArray(v?.vehicule_poids_lourds) ? v?.vehicule_poids_lourds?.join(', ') : v?.vehicule_poids_lourds}
						{v?.type == 'Véhicule léger' &&
							v?.vehicule_leger}
					</td>
					<td
						style={{
							padding: '1rem',
						}}
					>
						{v?.type == 'Poids lourds' &&
							Array.isArray(v?.options_poids_lourds) ? v?.options_poids_lourds?.join(', ') : v?.options_poids_lourds}
						{v?.type == 'Véhicule léger' &&
							Array.isArray(v?.options_vehicule_leger) ? v?.options_vehicule_leger?.join(', ') : v?.options_vehicule_leger}
					</td>
					<td
						style={{
							padding: '1rem',
						}}
					>
						{v.date_mise_circulation}
					</td>
					<td
						style={{
							padding: '1rem',
						}}
					>
						{v.immatriculation}
					</td>
					<td
						style={{
							padding: '1rem',
						}}
					>
						{v.location ? 'Oui' : 'Non'}
					</td>
					{v?.carte_grise &&
					<div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "0.5rem",marginTop:'1rem' }}>
                    <img
                    src={File}
                    alt="file-icon"
                  style={{
                    height: 20,
                    marginRight: "1rem",
                  }}
                />
                <span
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    marginRight: "1rem",
                  }}
                  onClick={() => {
                    window.open(`${BASE_URL}/${v?.carte_grise?.path}`);
                  }}
                >
                  {v?.carte_grise?.name }
                </span>
				{!isArchiveMode &&
				<button
				  type='button'
                  style={{
                   cursor: "pointer",
                   backgroundColor: "red",
                   color: "white",
                   border: "none",
                   padding: "8px 12px",
                   borderRadius: "4px",
                   }}
				   onClick={() => {
					setDocumentToReject({
					   key: `carte_grise_${index}_${v.immatriculation}`,
					   label: `Carte grise pour le véhicule immatriculé  ${v.immatriculation}`,
					 });
				   setModalIsOpenReject(true)
				}}
                
            >
            Rejeter
                </button>
                }
                    </div>
                    }
				</tr>
			))}
		    </tbody>
	                </table>
			        </FormSectionCard>
					</FormContentSection>

					<div style={{ height: '2rem' }} />
                     
					<FormContentSection>
						<FormSectionTitle>Rippeur</FormSectionTitle>
						<FormSectionCard>
							<div
								style={{
									width: '100%',
									display: 'flex',
									flexDirection: 'column',
									//marginBottom: '2.5rem',
								}}
							>
								<span
									style={{
										marginBottom: '1rem',
										color: 'black',
										fontSize: 14,
									}}
								>
									Nombre de ripper en plus du chauffeur
								</span>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<div>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='13.605'
											height='27.839'
											viewBox='0 0 13.605 27.839'
											style={{
												marginRight: '.5rem',
											}}
										>
											<g
												id='user'
												transform='translate(0.5 0.5)'
											>
												<path
													id='Path_11624'
													d='M31.488,8.816A3.412,3.412,0,1,1,34.9,5.4,3.412,3.412,0,0,1,31.488,8.816Zm0-6.054a2.645,2.645,0,1,0,2.645,2.645A2.645,2.645,0,0,0,31.488,2.762Z'
													transform='translate(-25.195 -1.992)'
													fill='#51c7f2'
													stroke='#51c7f2'
													strokeWidth='1'
												/>
												<path
													id='Path_11625'
													d='M30.917,33.11h-.752a.383.383,0,1,1,0-.767h.752A1.484,1.484,0,0,0,32.4,30.861V24.829a1.7,1.7,0,0,0-1.7-1.7H23.03a1.7,1.7,0,0,0-1.7,1.7v6.034a1.484,1.484,0,0,0,1.484,1.481h.725a.383.383,0,1,1,0,.767h-.725a2.252,2.252,0,0,1-2.249-2.249V24.829A2.472,2.472,0,0,1,23.03,22.36H30.7a2.472,2.472,0,0,1,2.469,2.469v6.034A2.252,2.252,0,0,1,30.917,33.11Z'
													transform='translate(-20.562 -14.552)'
													fill='#51c7f2'
													stroke='#51c7f2'
													strokeWidth='1'
												/>
												<path
													id='Path_11626'
													d='M38.027,48.646A2.029,2.029,0,0,1,36,46.619V40.231a.383.383,0,0,1,.767,0v6.388a1.26,1.26,0,0,0,2.52,0V34.509a.383.383,0,0,1,.767,0v12.11A2.029,2.029,0,0,1,38.027,48.646Z'
													transform='translate(-30.081 -21.807)'
													fill='#51c7f2'
													stroke='#51c7f2'
													strokeWidth='1'
												/>
												<path
													id='Path_11627'
													d='M29.453,48.646a2.029,2.029,0,0,1-2.027-2.027V34.509a.383.383,0,0,1,.767,0v12.11a1.26,1.26,0,0,0,2.52,0V40.231a.383.383,0,0,1,.767,0v6.388A2.029,2.029,0,0,1,29.453,48.646Z'
													transform='translate(-24.794 -21.807)'
													fill='#51c7f2'
													stroke='#51c7f2'
													strokeWidth='1'
												/>
											</g>
										</svg>
									</div>

									<CustomInput
										width={'100%'}
										type='number'
										disabled
										value={transporter?.rippeurs || 0}
										style={{
											backgroundColor: '#f0f0f0', 
											color: '#a9a9a9',           
											cursor: 'not-allowed'       
									    }}
									/>
								</div>
							</div>
						</FormSectionCard>
					</FormContentSection>

					<div style={{ height: '2rem' }} />

					<FormContentSection>
						<FormSectionTitle>
							Matériels supplémentaire
						</FormSectionTitle>
						<FormSectionCard>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									//marginBottom: '2.5rem',
								}}
							>
								<span
									style={{
										marginBottom: '1rem',
										color: 'black',
										fontSize: 14,
									}}
								>
									Matériels de manutention
								</span>

								<div
									style={{
										display: 'flex',
										flexWrap: 'wrap',
										flexDirection: 'row',
										marginBottom: '1rem',
									}}
								>
									{[
										'Diable',
										'Transpalette manuel',
										'Transpalette électrique',
										'Sangle',
									].map((item, index) => (
										<div
											key={String(index)}
											style={{
												width: '30%',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-start',
												marginBottom: '1rem',
											}}
										>
											<input
											    disabled
												type='checkbox'
												style={{
													marginRight: '1rem',
												}}
												
												checked={transporter?.manutention?.includes(item)}


												value={item}
												
											/>

											<span>{item}</span>
										</div>
									))}
								</div>
								
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									//marginBottom: '2.5rem',
								}}
							>
								<span
									style={{
										marginBottom: '1rem',
										color: 'black',
										fontSize: 14,
									}}
								>
									Epi
								</span>

								<div
									style={{
										display: 'flex',
										flexWrap: 'wrap',
										flexDirection: 'row',
										//marginBottom: '1rem',
									}}
								>
									{[
										'Casque',
										'Gants',
										'Masque',
										'Lunettes',
										'Chaussure de sécurité',
										'Gilet de visibilité',
									].map((item, index) => (
										<div
											key={String(index)}
											style={{
												width: '25%',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-start',
												marginBottom: '1rem',
											}}
										>
											<input
											    disabled
												type='checkbox'
												style={{
													marginRight: '1rem',
												}}
											
												checked={transporter?.epi?.includes(item)}

																								

												value={item}
											/>

											<span>{item}</span>
										</div>
									))}
								</div>
							</div>
							
						</FormSectionCard>
					</FormContentSection>
					<div style={{height:'2rem'}}/>
				    <FormContentSection>
                    <FormSectionTitle>Documents Administratifs</FormSectionTitle>
                    <FormSectionCard>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                     {[
                      {
                       label: "Attestation de régularité fiscale",
                       key: "fiscale",
                      },
                      {
                       label: "Attestation de vigilance Urssaf",
                       key: "urssaf",
                      },
                      {
                       label: "Assurance marchandises ",
                       key: "assurance_marchandises",
                      },
                      {
                       label: "Kbis",
                       key: "kbis",
                      },
                      {
                      label: "Licence de transport de marchandises de moins de 3,5t",
                      key: "licence_moins35t",
                      },
                      {
                       label: "Licence de transport de marchandises de plus de 3,5t ",
                       key: "licence_plus35t",
                      },
					  { label: 'Capacite transport',
						key: 'capacite_transport',
					  },
                      {
                       label: "Pièce d'identité ",
                       key: "piece_identite",
                      },
					  { label: 'Rib',
						key: 'rib',
					  },

                    ].reduce((acc, doc, index, array) => {
                    if (index % 2 === 0) {
                    acc.push(array.slice(index, index + 2));
                    }
                    return acc;
                    }, []).map((docPair, rowIndex) => (
                    <div key={rowIndex} style={{ display: "flex", flexDirection: "row", gap: "2rem", paddingBottom: "2rem" }}>
                    {docPair.map((doc, index) => (
                    <div key={index} style={{ width: "50%" }}>
                    <span style={{ color: '#262626',paddingBottom: '1rem' }}>{doc.label}</span>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "0.5rem",marginTop:'1rem' }}>
                    <img
                    src={File}
                    alt="file-icon"
                  style={{
                    height: 20,
                    marginRight: "1rem",
                  }}
                />
				{transporter?.[doc.key]?.path ? (
                <span
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    marginRight: "1rem",
                  }}
                  onClick={() => {
                    window.open(`${BASE_URL}/${transporter?.[doc.key]?.path}`);
                  }}
                >
                  {transporter?.[doc.key]?.name }
                </span>
				) : (
					<span
						style={{
							color: "red",
							marginRight: "1rem",
						}}
					>
						Non disponible
					</span>
				)}
                {!isArchiveMode  && transporter?.[doc.key]?.path  &&
				<button
				  type='button'
                  style={{
                   cursor: "pointer",
                   backgroundColor: "red",
                   color: "white",
                   border: "none",
                   padding: "8px 12px",
                   borderRadius: "4px",
                   }}
                onClick={() => {
                   setDocumentToReject({
                     key: doc.key,
                     label: doc.label,
                });
                setModalIsOpenReject(true);
              }}
            >
            Rejeter
                </button>
                }

                    </div>
            </div>
          ))}
        </div>
      ))}
                    </div>
                   </FormSectionCard>
                    </FormContentSection>

					<div style={{ height: '2rem' }} />

				</form>
                }
			</div>
			<Modal
				isOpen={modalIsOpenDelete}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					>Supprimer Transporteur</div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>Êtes-vous sûr de vouloir supprimer ce Transporteur ? </p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
					
						onClick={async () => {	
							setLoading(true)
							   try {
								const res=await softDeleteTransporter(transporter._id,token)
								toast.success(res.message, {
									style: {
										fontFamily: 'Montserrat',
									},
								})
								history.goBack()
							   } catch (error) {
								if (error.response && error.response.status === 403) {
									toast.error(error.response.data.message, {
										style: {
											fontFamily: 'Montserrat',
										},
									});
								}else{
									toast.error("Une erreur s'est produite", {
										style: {
											fontFamily: 'Montserrat',
										},
									});
								}
							   }finally {
								setModalIsOpenDelete(false)
								setLoading(false)
							}
							  
                              
                            }}

						disabled={loading}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
							display: 'flex',
					        justifyContent: 'center',
					        alignItems: 'center'
						}}
					>
					{loading ? 	<div className='loader'></div> : 'Confirmer'} 

					</button>
					<button
						onClick={async () => {
							setModalIsOpenDelete(false)
												
						}}
						style={{
							// padding: '1rem 1.5rem',
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	       </Modal>	
		   <Modal
				isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					>{suspendre ? 'Activer Transporteur' : 'Suspendre Transporteur'}
		            </div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>
						{`Êtes-vous sûr de vouloir ${suspendre ? 'Activer' : 'Suspendre'} ce Transporteur ?`}

						 </p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
					
						onClick={async () => {	
							setLoading(true)
							try {
								const data={suspendre:!suspendre}
								const res=await toggleSuspendreTransporter(
									transporter._id,
									data,
									token
						        )
						        toast.success(res.message, {
							    style: {
								fontFamily: 'Montserrat',
							    },
						})
						setSuspendre(!suspendre)
						history.goBack()
							} catch (error) {
								if (error.response && error.response.status === 403) {
									toast.error(error.response.data.message, {
										style: {
											fontFamily: 'Montserrat',
										},
									});
								}else{
									toast.error("Une erreur s'est produite", {
										style: {
											fontFamily: 'Montserrat',
										},
									});
								}
							}finally {
								setModalIsOpen(false)
								setLoading(false)
							}
                              
                            }}
						disabled={loading}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
							display: 'flex',
					        justifyContent: 'center',
					        alignItems: 'center'
						}}
					>
				   	{loading ? 	<div className='loader'></div> : 'Confirmer'} 

					</button>
					<button
						onClick={async () => {
							setModalIsOpen(false)
												
						}}
						style={{
							// padding: '1rem 1.5rem',
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	       </Modal>
		   <Modal
				isOpen={modalIsOpenReject}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Confirmation de rejet'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					>
						 Rejeter document
		            </div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>
						Êtes-vous sûr de vouloir rejeter ce document ?
						 </p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
					
						onClick={async () => {	
							setLoading(true)
							try {
								await sendTransporterDocument(id,documentToReject,token)
								toast.success('Document rejeté avec succès !',{
									style: {
										fontFamily: 'Montserrat',
									},
								});
							} catch (error) {
								toast.error("Une erreur s'est produite", {
									style: {
										fontFamily: 'Montserrat',
									},
								})
							}finally{
								setModalIsOpenReject(false)
								setDocumentToReject(null)
								setLoading(false)
							}
						}}
						disabled={loading}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
							display: 'flex',
					        justifyContent: 'center',
					        alignItems: 'center'
						}}
					>
				   {loading ? 	<div className='loader'></div> : 'Confirmer'} 

					</button>
					<button
						onClick={ () => {
							setModalIsOpenReject(false)
												
						}}
						style={{
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	       </Modal>

		   <Modal
				isOpen={modalIsOpenUpdate}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='modification transporteur'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					>
                    Modifier le compte		            
                    </div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>
						Êtes-vous sûr de vouloir modifier le compte de ce Transporteur ??
						 </p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
					
						onClick={async () => {	
							setLoading(true)
							try {
								await updateTransporter(transporter._id, { tva: formValues.tva }, token)
								toast.success("Le compte a été modifié avec succès", {
									style: {
										fontFamily: 'Montserrat',
									},
								});
								history.goBack();
							} catch (error) {
								toast.error("Une erreur s'est produite", {
									style: {
										fontFamily: 'Montserrat',
									},
								})
							}finally{
								setModalIsOpenUpdate(false)
								setLoading(false)
							}
						}}
						disabled={loading}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
							display: 'flex',
					        justifyContent: 'center',
					        alignItems: 'center'
						}}
					>
				   {loading ? 	<div className='loader'></div> : 'Confirmer'} 

					</button>
					<button
						onClick={ () => {
							setModalIsOpenUpdate(false)
												
						}}
						style={{
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	       </Modal>
		</LayoutAdmin>
	)
}

export default SingleTransporter
